/* eslint-disable @typescript-eslint/no-duplicate-enum-values */
export const authorizationLocalStorageKey = "authorization";

export enum InitAuthenticationErrorCode {
  INVALID_USERNAME = "INVALID_USERNAME",
  INVALID_PASSWORD = "INVALID_PASSWORD",
  INVALID_USER_STATUS = "INVALID_USER_STATUS",
  USER_NOT_FOUND = "USER_NOT_FOUND",
  UUID_NOT_FOUND = "UUID_NOT_FOUND",
  INVALID_INPUT = "INVALID_INPUT"
}

export enum AdminInitAuthenticationErrorCode {
  INVALID_USERNAME = "INVALID_USERNAME",
  USER_NOT_FOUND = "USER_NOT_FOUND"
}

export enum CreateAdminUserErrorCode {
  INVALID_USERNAME = "INVALID_USERNAME",
  USERNAME_ALREADY_EXIST = "USERNAME_ALREADY_EXIST"
}

export enum CommonAuthErrorCode {
  INVALID_USERNAME = "INVALID_USERNAME"
}

export enum CommonVerificationUsernameErrorCode {
  INVALID_USERNAME = "INVALID_USERNAME"
}

export enum RefreshTokenErrorCode {
  USER_NOT_FOUND = "USER_NOT_FOUND",
  INVALID_REFRESH_TOKEN = "INVALID_REFRESH_TOKEN",
  UNKNOWN_ERROR = "UNKNOWN_ERROR"
}

export enum ConfirmForgotPasswordErrorCode {
  INVALID_CONFIRMATION_CODE = "INVALID_CONFIRMATION_CODE",
  USER_NOT_FOUND = "USER_NOT_FOUND",
  INVALID_PASSWORD = "INVALID_PASSWORD",
  UNKNOWN_ERROR = "UNKNOWN_ERROR"
}

export enum SetPasswordErrorCode {
  INVALID_PASSWORD = "INVALID_PASSWORD",
  INVALID_SESSION = "INVALID_SESSION",
  USER_NOT_FOUND = "USER_NOT_FOUND"
}

export enum ResendVerificationCodeErrorCode {
  USER_NOT_FOUND = "USER_NOT_FOUND",
  INVALID_USER_STATUS = "INVALID_USER_STATUS"
}

export enum ForgotPasswordErrorCode {
  USER_NOT_FOUND = "USER_NOT_FOUND",
  INVALID_USER_STATUS = "INVALID_USER_STATUS"
}

export enum CreateManufacturerErrorCode {
  NAME_ALREADY_EXIST = "NAME_ALREADY_EXIST",
  SUPPLIER_ALREADY_EXIST = "SUPPLIER_ALREADY_EXIST"
}

export enum UpdateManufacturerErrorCode {
  MANUFACTURER_NOT_FOUND = "MANUFACTURER_NOT_FOUND"
}

export enum FindOneManufacturerErrorCode {
  MANUFACTURER_NOT_FOUND = "MANUFACTURER_NOT_FOUND"
}

export enum SetManufacturerStatusErrorCode {
  MANUFACTURER_NOT_FOUND = "MANUFACTURER_NOT_FOUND"
}

export enum SetManufacturerDirectDistributionStatusErrorCode {
  MANUFACTURER_NOT_FOUND = "MANUFACTURER_NOT_FOUND",
  MANUFACTURER_ALREADY_HAS_DIRECT_DISTRIBUTION_ENABLED = "MANUFACTURER_ALREADY_HAS_DIRECT_DISTRIBUTION_ENABLED",
  MANUFACTURER_HAS_NO_DIRECT_DISTRIBUTION = "MANUFACTURER_HAS_NO_DIRECT_DISTRIBUTION",
  SUPPLIER_ALREADY_EXIST = "SUPPLIER_ALREADY_EXIST"
}

export enum CreateBrandErrorCode {
  NAME_ALREADY_EXIST = "NAME_ALREADY_EXIST",
  INTERNAL_CODE_ALREADY_EXIST = "INTERNAL_CODE_ALREADY_EXIST",
  SUPPLIER_NOT_FOUND = "SUPPLIER_NOT_FOUND"
}

export enum UpdateBrandErrorCode {
  BRAND_NOT_FOUND = "BRAND_NOT_FOUND",
  NAME_ALREADY_EXIST = "NAME_ALREADY_EXIST",
  INTERNAL_CODE_ALREADY_EXIST = "INTERNAL_CODE_ALREADY_EXIST",
  SUPPLIER_NOT_FOUND = "SUPPLIER_NOT_FOUND"
}

export enum UpdateAvailabilityErrorCode {
  STORE_NOT_FOUND = "STORE_NOT_FOUND"
}

export enum FindOneBrandErrorCode {
  BRAND_NOT_FOUND = "BRAND_NOT_FOUND",
  SUPPLIER_NOT_FOUND = "SUPPLIER_NOT_FOUND"
}

export enum SetBrandStatusErrorCode {
  BRAND_NOT_FOUND = "BRAND_NOT_FOUND"
}

export enum PromotionType {
  DISCOUNT_SIMPLE = "DISCOUNT_SIMPLE",
  DISCOUNT_MULTIPLE = "DISCOUNT_MULTIPLE",
  BONUS_INDIVIDUAL = "BONUS_INDIVIDUAL",
  BONUS_CROSSED = "BONUS_CROSSED"
}

export enum CreatePromotionErrorCode {
  SUPPLIER_NOT_FOUND = "SUPPLIER_NOT_FOUND",
  DISCOUNT_DATA_EMPTY = "DISCOUNT_DATA_EMPTY",
  BONUS_DATA_EMPTY = "BONUS_DATA_EMPTY",
  BRAND_NOT_FOUND = "BRAND_NOT_FOUND",
  CATEGORY_NOT_FOUND = "CATEGORY_NOT_FOUND",
  PRODUCT_NOT_FOUND = "PRODUCT_NOT_FOUND",
  BONUS_PRODUCT_NOT_FOUND = "BONUS_PRODUCT_NOT_FOUND",
  BONUS_DISPLAY_PRODUCT_NOT_FOUND = "BONUS_DISPLAY_PRODUCT_NOT_FOUND",
  NAME_AND_INTERNAL_CODE_ALREADY_EXIST = "NAME_AND_INTERNAL_CODE_ALREADY_EXIST",
  NAME_ALREADY_EXIST = "NAME_ALREADY_EXIST",
  INTERNAL_CODE_ALREADY_EXIST = "INTERNAL_CODE_ALREADY_EXIST",
  START_DATE_AFTER_END_DATE = "START_DATE_AFTER_END_DATE",
  START_DATE_IN_THE_PAST = "START_DATE_IN_THE_PAST",
  PRODUCTS_DATA_EMPTY = "PRODUCTS_DATA_EMPTY",
  PROMOTION_HAS_BONUS = "PROMOTION_HAS_BONUS",
  PROMOTION_HAS_DISCOUNT = "PROMOTION_HAS_DISCOUNT",
  PROMOTION_HAS_MULTIPLE_PRODUCTS = "PROMOTION_HAS_MULTIPLE_PRODUCTS",
  SIMPLE_PROMOTION_HAS_TITLE = "SIMPLE_PROMOTION_HAS_TITLE",
  SIMPLE_PROMOTION_HAS_DESCRIPTION = "SIMPLE_PROMOTION_HAS_DESCRIPTION",
  MULTIPLE_PROMOTION_HAS_SINGLE_PRODUCT = "MULTIPLE_PROMOTION_HAS_SINGLE_PRODUCT",
  INDIVIDUAL_PROMOTION_REFERS_TO_OTHER_PRODUCT = "INDIVIDUAL_PROMOTION_REFERS_TO_OTHER_PRODUCT",
  PROMOTION_HAS_NO_TITLE = "PROMOTION_HAS_NO_TITLE",
  TIERS_OVERLAP = "TIERS_OVERLAP",
  TIER_MAX_LOWER_THAN_MIN = "TIER_MAX_LOWER_THAN_MIN",
  TIER_HAS_NO_MAX_VALUE = "TIER_HAS_NO_MAX_VALUE",
  INVALID_BOX_RATIO = "INVALID_BOX_RATIO",
  PRODUCT_HAS_MORE_THAN_ONE_DISPLAY_PRODUCT = "PRODUCT_HAS_MORE_THAN_ONE_DISPLAY_PRODUCT",
  PROMOTION_GROUP_NOT_EXIST = "PROMOTION_GROUP_NOT_EXIST",
  PROMOTION_GROUP_ALREADY_IN_PROMOTION = "PROMOTION_GROUP_ALREADY_IN_PROMOTION",
  PROMOTION_GROUP_HAS_CONFLICTS = "PROMOTION_GROUP_HAS_CONFLICTS"
}

export enum UpdatePromotionErrorCode {
  SUPPLIER_NOT_FOUND = "SUPPLIER_NOT_FOUND",
  BRAND_NOT_FOUND = "BRAND_NOT_FOUND",
  CATEGORY_NOT_FOUND = "CATEGORY_NOT_FOUND",
  PRODUCT_NOT_FOUND = "PRODUCT_NOT_FOUND",
  BONUS_PRODUCT_NOT_FOUND = "BONUS_PRODUCT_NOT_FOUND",
  BONUS_DISPLAY_PRODUCT_NOT_FOUND = "BONUS_DISPLAY_PRODUCT_NOT_FOUND",
  NAME_AND_INTERNAL_CODE_ALREADY_EXIST = "NAME_AND_INTERNAL_CODE_ALREADY_EXIST",
  NAME_ALREADY_EXIST = "NAME_ALREADY_EXIST",
  INTERNAL_CODE_ALREADY_EXIST = "INTERNAL_CODE_ALREADY_EXIST",
  PROMOTION_HAS_BONUS = "PROMOTION_HAS_BONUS",
  PROMOTION_HAS_DISCOUNT = "PROMOTION_HAS_DISCOUNT",
  PROMOTION_NOT_FOUND = "PROMOTION_NOT_FOUND",
  PRODUCTS_DATA_EMPTY = "PRODUCTS_DATA_EMPTY",
  START_DATE_IN_THE_PAST = "START_DATE_IN_THE_PAST",
  START_DATE_AFTER_END_DATE = "START_DATE_AFTER_END_DATE",
  PROMOTION_HAS_MULTIPLE_PRODUCTS = "PROMOTION_HAS_MULTIPLE_PRODUCTS",
  SIMPLE_PROMOTION_HAS_TITLE = "SIMPLE_PROMOTION_HAS_TITLE",
  SIMPLE_PROMOTION_HAS_DESCRIPTION = "SIMPLE_PROMOTION_HAS_DESCRIPTION",
  MULTIPLE_PROMOTION_HAS_SINGLE_PRODUCT = "MULTIPLE_PROMOTION_HAS_SINGLE_PRODUCT",
  INDIVIDUAL_PROMOTION_REFERS_TO_OTHER_PRODUCT = "INDIVIDUAL_PROMOTION_REFERS_TO_OTHER_PRODUCT",
  PROMOTION_HAS_NO_TITLE = "PROMOTION_HAS_NO_TITLE",
  TIERS_OVERLAP = "TIERS_OVERLAP",
  TIER_MAX_LOWER_THAN_MIN = "TIER_MAX_LOWER_THAN_MIN",
  TIER_HAS_NO_MAX_VALUE = "TIER_HAS_NO_MAX_VALUE",
  INTERNAL_CODE_NON_EDITABLE = "INTERNAL_CODE_NON_EDITABLE",
  INVALID_BOX_RATIO = "INVALID_BOX_RATIO",
  NEW_PRODUCT_WHEN_PROMOTION_HAS_STORES = "NEW_PRODUCT_WHEN_PROMOTION_HAS_STORES",
  PROMOTION_GROUP_NOT_EXIST = "PROMOTION_GROUP_NOT_EXIST",
  PROMOTION_GROUP_ALREADY_IN_PROMOTION = "PROMOTION_GROUP_ALREADY_IN_PROMOTION",
  PROMOTION_GROUP_HAS_CONFLICTS = "PROMOTION_GROUP_HAS_CONFLICTS"
}
export enum CreateScheduledNoticiationTypeErrorCode {
  SUPPLIER_SCHEDULED_NOTIFICATION_TYPE_ALREADY_EXIST = "SUPPLIER_SCHEDULED_NOTIFICATION_TYPE_ALREADY_EXIST",
  SUPPLIER_NOT_EXIST = "SUPPLIER_NOT_EXIST"
}

export enum ScheduleFileShareNotificationErrorCode {
  SUPPLIER_SCHEDULED_NOTIFICATION_NOT_EXIST = "SUPPLIER_SCHEDULED_NOTIFICATION_NOT_EXIST"
}

export enum AddMessageToFileShareNotification {
  STORE_NOT_FOUND = "STORE_NOT_FOUND"
}
export enum ScheduleSuggestedOrderNotificationErrorCode {
  SUPPLIER_SCHEDULED_NOTIFICATION_NOT_EXIST = "SUPPLIER_SCHEDULED_NOTIFICATION_NOT_EXIST"
}

export enum ForceRunNotificationErrorCode {
  SCHEDULED_NOTIFICATION_NOT_EXIST = "SCHEDULED_NOTIFICATION_NOT_EXIST"
}

export enum CreateScheduledNotificationErrorCode {
  SUPPLIER_NOT_EXIST = "SUPPLIER_NOT_EXIST",
  SUPPLIER_SCHEDULED_NOTIFICATION_ALREADY_EXIST = "SUPPLIER_SCHEDULED_NOTIFICATION_ALREADY_EXIST",
  SUPPLIER_SCHEDULED_NOTIFICATION_NOT_EXIST = "SUPPLIER_SCHEDULED_NOTIFICATION_NOT_EXIST"
}

export enum SuggestedOrderNotificationScheduledErrorCode {
  PRODUCT_NOT_FOUND = "PRODUCT_NOT_FOUND"
}

export enum AddMessageToSuggestedOrderScheduledNotification {
  PRODUCT_NOT_FOUND = "PRODUCT_NOT_FOUND",
  STORE_NOT_FOUND = "STORE_NOT_FOUND",
  DISPLAY_NOT_FOUND = "DISPLAY_NOT_FOUND"
}

export enum SetPromotionStatusErrorCode {
  SUPPLIER_NOT_FOUND = "SUPPLIER_NOT_FOUND",
  PROMOTION_NOT_FOUND = "PROMOTION_NOT_FOUND"
}

export enum FindPromotionsErrorCode {
  SUPPLIER_NOT_FOUND = "SUPPLIER_NOT_FOUND"
}

export enum FindPromotionsForStoreErrorCode {
  STORE_NOT_BELONG_TO_USER = "STORE_NOT_BELONG_TO_USER"
}

export enum FindOnePromotionErrorCode {
  SUPPLIER_NOT_FOUND = "SUPPLIER_NOT_FOUND",
  PROMOTION_NOT_FOUND = "PROMOTION_NOT_FOUND"
}

export enum FindStoresInPromotionErrorCode {
  SUPPLIER_NOT_FOUND = "SUPPLIER_NOT_FOUND",
  PROMOTION_NOT_FOUND = "PROMOTION_NOT_FOUND"
}

export enum FindPromotionGroupsInPromotionErrorCode {
  SUPPLIER_NOT_FOUND = "SUPPLIER_NOT_FOUND",
  PROMOTION_NOT_FOUND = "PROMOTION_NOT_FOUND"
}

export enum FindProductWithPromotionsErrorCode {
  STORE_NOT_FOUND = "STORE_NOT_FOUND",
  PRODUCT_NOT_FOUND = "PRODUCT_NOT_FOUND"
}

export enum FindPromotionByCodeForSupplierErrorCode {
  SUPPLIER_NOT_FOUND = "SUPPLIER_NOT_FOUND",
  PROMOTION_NOT_FOUND = "PROMOTION_NOT_FOUND"
}

export enum AddPromotionGroupToPromotionErrorCode {
  SUPPLIER_NOT_FOUND = "SUPPLIER_NOT_FOUND",
  PROMOTION_NOT_FOUND = "PROMOTION_NOT_FOUND",
  PROMOTION_GROUP_NOT_FOUND = "PROMOTION_GROUP_NOT_FOUND",
  PROMOTION_GROUP_ALREADY_IN_PROMOTION = "PROMOTION_GROUP_ALREADY_IN_PROMOTION",
  PROMOTION_GROUP_HAS_CONFLICTS = "PROMOTION_GROUP_HAS_CONFLICTS"
}

export enum RemovePromotionGroupFromPromotionErrorCode {
  SUPPLIER_NOT_FOUND = "SUPPLIER_NOT_FOUND",
  PROMOTION_NOT_FOUND = "PROMOTION_NOT_FOUND",
  PROMOTION_GROUP_NOT_FOUND = "PROMOTION_GROUP_NOT_FOUND",
  PROMOTION_GROUP_NOT_IN_PROMOTION = "PROMOTION_GROUP_NOT_IN_PROMOTION"
}

export enum CreateSupplierErrorCode {
  NAME_ALREADY_EXIST = "NAME_ALREADY_EXIST"
}

export enum UpdateSupplierErrorCode {
  SUPPLIER_NOT_FOUND = "SUPPLIER_NOT_FOUND"
}

export enum SetSupplierStatusErrorCode {
  SUPPLIER_NOT_FOUND = "SUPPLIER_NOT_FOUND"
}

export enum CreateEmailUserSupplier {
  SUPPLIER_NOT_FOUND = "SUPPLIER_NOT_FOUND",
  INVALID_EMAIL = "INVALID_EMAIL",
  USERNAME_ALREADY_EXIST = "USERNAME_ALREADY_EXIST"
}

export enum FindMySiteConfigurationErrorCode {
  SITE_CONFIGURATION_NOT_FOUND = "SITE_CONFIGURATION_NOT_FOUND",
  SITE_CONFIGURATION_ALREADY_EXIST = "SITE_CONFIGURATION_ALREADY_EXIST"
}

export enum CreateSiteConfigurationErrorCode {
  SITE_CONFIGURATION_ALREADY_EXIST = "SITE_CONFIGURATION_ALREADY_EXIST",
  SUPPLIER_NOT_FOUND = "SUPPLIER_NOT_FOUND"
}

export enum UpdateSiteConfigurationErrorCode {
  SITE_CONFIGURATION_NOT_FOUND = "SITE_CONFIGURATION_NOT_FOUND"
}

export enum CreateCategoryErrorCode {
  NAME_ALREADY_EXIST_FOR_CATEGORY_LEVEL = "NAME_ALREADY_EXIST_FOR_CATEGORY_LEVEL",
  INTERNAL_CODE_ALREADY_EXIST = "INTERNAL_CODE_ALREADY_EXIST",
  SUPPLIER_NOT_FOUND = "SUPPLIER_NOT_FOUND",
  PARENT_CATEGORY_NOT_FOUND = "PARENT_CATEGORY_NOT_FOUND",
  PARENT_CATEGORY_HAS_PRODUCTS = "PARENT_CATEGORY_HAS_PRODUCTS",
  UPLOADING_IMAGE_TO_NON_PARENT_CATEGORY = "UPLOADING_IMAGE_TO_NON_PARENT_CATEGORY"
}

export enum FindParentCategoriesErrorCode {
  SUPPLIER_NOT_FOUND = "SUPPLIER_NOT_FOUND"
}

export enum FindChildCategoryErrorCode {
  SUPPLIER_NOT_FOUND = "SUPPLIER_NOT_FOUND",
  CATEGORY_NOT_FOUND = "CATEGORY_NOT_FOUND"
}

export enum FindOneCategoryErrorCode {
  SUPPLIER_NOT_FOUND = "SUPPLIER_NOT_FOUND",
  CATEGORY_NOT_FOUND = "CATEGORY_NOT_FOUND"
}

export enum UpdateCategoryErrorCode {
  NAME_ALREADY_EXIST_FOR_CATEGORY_LEVEL = "NAME_ALREADY_EXIST_FOR_CATEGORY_LEVEL",
  INTERNAL_CODE_ALREADY_EXIST = "INTERNAL_CODE_ALREADY_EXIST",
  SUPPLIER_NOT_FOUND = "SUPPLIER_NOT_FOUND",
  CATEGORY_NOT_FOUND = "CATEGORY_NOT_FOUND",
  UPLOADING_IMAGE_TO_NON_PARENT_CATEGORY = "UPLOADING_IMAGE_TO_NON_PARENT_CATEGORY"
}

export enum SetCategoryStatusErrorCode {
  CATEGORY_NOT_FOUND = "CATEGORY_NOT_FOUND"
}

export enum DeleteCategoryErrorCode {
  SUPPLIER_NOT_FOUND = "SUPPLIER_NOT_FOUND",
  CATEGORY_NOT_FOUND = "CATEGORY_NOT_FOUND",
  CATEGORY_HAS_SUBCATEGORIES = "CATEGORY_HAS_SUBCATEGORIES",
  CATEGORY_HAS_PRODUCTS = "CATEGORY_HAS_PRODUCTS"
}
export enum CreateProductErrorCode {
  NAME_ALREADY_EXIST = "NAME_ALREADY_EXIST",
  CODE_ALREADY_EXIST = "CODE_ALREADY_EXIST",
  BRAND_NOT_FOUND = "BRAND_NOT_FOUND",
  BRAND_NOT_ENABLED = "BRAND_NOT_ENABLED",
  SUPPLIER_NOT_FOUND = "SUPPLIER_NOT_FOUND",
  SUPPLIER_NOT_ENABLED = "SUPPLIER_NOT_ENABLED",
  CATEGORY_NOT_FOUND = "CATEGORY_NOT_FOUND",
  CATEGORY_NOT_ENABLED = "CATEGORY_NOT_ENABLED"
}

export enum UpdateProductErrorCode {
  NAME_ALREADY_EXIST = "NAME_ALREADY_EXIST",
  CODE_ALREADY_EXIST = "CODE_ALREADY_EXIST",
  PRODUCT_NOT_FOUND = "PRODUCT_NOT_FOUND",
  BRAND_NOT_FOUND = "BRAND_NOT_FOUND",
  BRAND_NOT_ENABLED = "BRAND_NOT_ENABLED",
  SUPPLIER_NOT_FOUND = "SUPPLIER_NOT_FOUND",
  SUPPLIER_NOT_ENABLED = "SUPPLIER_NOT_ENABLED",
  CATEGORY_NOT_FOUND = "CATEGORY_NOT_FOUND",
  CATEGORY_NOT_ENABLED = "CATEGORY_NOT_ENABLED"
}

export enum FindOneProductErrorCode {
  PRODUCT_NOT_FOUND = "PRODUCT_NOT_FOUND"
}

export enum FindOneProductByInternalCodeErrorCode {
  PRODUCT_NOT_FOUND = "PRODUCT_NOT_FOUND"
}

export enum EnableProductErrorCode {
  PRODUCT_NOT_FOUND = "PRODUCT_NOT_FOUND"
}

export enum DisableProductErrorCode {
  PRODUCT_NOT_FOUND = "PRODUCT_NOT_FOUND"
}

export enum NotifyProductAvailabilityErrorCode {
  PRODUCT_NOT_FOUND = "PRODUCT_NOT_FOUND",
  NOTIFICATION_ALREADY_EXIST = "NOTIFICATION_ALREADY_EXIST"
}

export enum FormatImage {
  PNG = "png",
  JPG = "jpg",
  JPEG = "jpeg",
  WEBP = "webp"
}

export enum FormatImageErrorCode {
  FORMAT_NOT_PERMITTED = "FORMAT_NOT_PERMITTED",
  FORMAT_NOT_FOUND = "FORMAT_NOT_FOUND"
}

export enum FileUploadErrorCode {
  FILE_ENTITY_NOT_FOUND = "FILE_ENTITY_NOT_FOUND",
  FILE_FORMAT_NOT_FOUND = "FILE_FORMAT_NOT_FOUND"
}

export enum CreateStockGroupErrorCode {
  SUPPLIER_NOT_FOUND = "SUPPLIER_NOT_FOUND",
  NAME_ALREADY_EXIST = "NAME_ALREADY_EXIST",
  INTERNAL_CODE_ALREADY_EXIST = "INTERNAL_CODE_ALREADY_EXIST"
}

export enum UpdateStockGroupErrorCode {
  STOCK_GROUP_NOT_FOUND = "STOCK_GROUP_NOT_FOUND",
  SUPPLIER_NOT_FOUND = "SUPPLIER_NOT_FOUND",
  NAME_ALREADY_EXIST = "NAME_ALREADY_EXIST",
  INTERNAL_CODE_ALREADY_EXIST = "INTERNAL_CODE_ALREADY_EXIST"
}

export enum DeleteStockGroupErrorCode {
  STOCK_GROUP_NOT_FOUND = "STOCK_GROUP_NOT_FOUND",
  SUPPLIER_NOT_FOUND = "SUPPLIER_NOT_FOUND"
}

export enum SetStockGroupStatusErrorCode {
  STOCK_GROUP_NOT_FOUND = "STOCK_GROUP_NOT_FOUND",
  SUPPLIER_NOT_FOUND = "SUPPLIER_NOT_FOUND"
}

export enum AddStoreToStockGroupErrorCode {
  STOCK_GROUP_NOT_FOUND = "STOCK_GROUP_NOT_FOUND",
  SUPPLIER_NOT_FOUND = "SUPPLIER_NOT_FOUND",
  STORE_NOT_FOUND = "STORE_NOT_FOUND",
  STORE_ALREADY_IN_STOCK_GROUP = "STORE_ALREADY_IN_STOCK_GROUP"
}

export enum RemoveStoreFromStockGroupErrorCode {
  STOCK_GROUP_NOT_FOUND = "STOCK_GROUP_NOT_FOUND",
  SUPPLIER_NOT_FOUND = "SUPPLIER_NOT_FOUND",
  STORE_NOT_FOUND = "STORE_NOT_FOUND",
  STORE_NOT_IN_STOCK_GROUP = "STORE_NOT_IN_STOCK_GROUP"
}

export enum FindStockGroupsErrorCode {
  SUPPLIER_NOT_FOUND = "SUPPLIER_NOT_FOUND"
}

export enum FindOneStockGroupErrorCode {
  SUPPLIER_NOT_FOUND = "SUPPLIER_NOT_FOUND",
  STOCK_GROUP_NOT_FOUND = "STOCK_GROUP_NOT_FOUND"
}

export enum FindStoresInStockGroupErrorCode {
  STOCK_GROUP_NOT_FOUND = "STOCK_GROUP_NOT_FOUND",
  SUPPLIER_NOT_FOUND = "SUPPLIER_NOT_FOUND"
}

export enum CreatePromotionGroupErrorCode {
  PROMOTION_GROUP_NOT_FOUND = "PROMOTION_GROUP_NOT_FOUND",
  SUPPLIER_NOT_FOUND = "SUPPLIER_NOT_FOUND",
  NAME_ALREADY_EXIST = "NAME_ALREADY_EXIST",
  INTERNAL_CODE_ALREADY_EXIST = "INTERNAL_CODE_ALREADY_EXIST"
}

export enum UpdatePromotionGroupErrorCode {
  PROMOTION_GROUP_NOT_FOUND = "PROMOTION_GROUP_NOT_FOUND",
  SUPPLIER_NOT_FOUND = "SUPPLIER_NOT_FOUND",
  NAME_ALREADY_EXIST = "NAME_ALREADY_EXIST",
  INTERNAL_CODE_ALREADY_EXIST = "INTERNAL_CODE_ALREADY_EXIST"
}

export enum DeletePromotionGroupErrorCode {
  PROMOTION_GROUP_NOT_FOUND = "PROMOTION_GROUP_NOT_FOUND",
  SUPPLIER_NOT_FOUND = "SUPPLIER_NOT_FOUND"
}

export enum SetPromotionGroupStatusErrorCode {
  PROMOTION_GROUP_NOT_FOUND = "PROMOTION_GROUP_NOT_FOUND",
  SUPPLIER_NOT_FOUND = "SUPPLIER_NOT_FOUND"
}

export enum AddStoreToPromotionGroupErrorCode {
  PROMOTION_GROUP_NOT_FOUND = "PROMOTION_GROUP_NOT_FOUND",
  SUPPLIER_NOT_FOUND = "SUPPLIER_NOT_FOUND",
  STORE_NOT_FOUND = "STORE_NOT_FOUND",
  STORE_ALREADY_IN_PROMOTION_GROUP = "STORE_ALREADY_IN_PROMOTION_GROUP"
}

export enum RemoveStoreFromPromotionGroupErrorCode {
  PROMOTION_GROUP_NOT_FOUND = "PROMOTION_GROUP_NOT_FOUND",
  SUPPLIER_NOT_FOUND = "SUPPLIER_NOT_FOUND",
  STORE_NOT_FOUND = "STORE_NOT_FOUND",
  STORE_NOT_IN_PROMOTION_GROUP = "STORE_NOT_IN_PROMOTION_GROUP"
}

export enum FindPromotionGroupsErrorCode {
  PROMOTION_GROUP_NOT_FOUND = "PROMOTION_GROUP_NOT_FOUND",
  SUPPLIER_NOT_FOUND = "SUPPLIER_NOT_FOUND"
}

export enum FindStoresInPromotionGroupErrorCode {
  PROMOTION_GROUP_NOT_FOUND = "PROMOTION_GROUP_NOT_FOUND",
  SUPPLIER_NOT_FOUND = "SUPPLIER_NOT_FOUND"
}

export enum FindOnePromotionGroupErrorCode {
  PROMOTION_GROUP_NOT_FOUND = "PROMOTION_GROUP_NOT_FOUND"
}

export enum CreateStoreErrorCode {
  INVALID_SUPPLIER_ID = "INVALID_SUPPLIER_ID",
  LEGAL_ID_ALREADY_EXIST = "LEGAL_ID_ALREADY_EXIST",
  INTERNAL_CODE_ALREADY_EXIST = "INTERNAL_CODE_ALREADY_EXIST",
  CURRENCY_NOT_EXIST = "CURRENCY_NOT_EXIST",
  PRICE_LIST_NOT_EXIST = "PRICE_LIST_NOT_EXIST",
  PROMOTION_GROUP_NOT_EXIST = "PROMOTION_GROUP_NOT_EXIST",
  STOCK_GROUP_NOT_EXIST = "STOCK_GROUP_NOT_EXIST"
}

export enum AddInternalCodeStoreErrorCode {
  INVALID_SUPPLIER_ID = "INVALID_SUPPLIER_ID",
  INTERNAL_CODE_ALREADY_EXIST = "INTERNAL_CODE_ALREADY_EXIST"
}

export enum CountryCodeErrorCode {
  INVALID_COUNTRY_CODE = "INVALID_COUNTRY_CODE"
}

export enum UpdateStoreErrorCode {
  INVALID_SUPPLIER_ID = "INVALID_SUPPLIER_ID",
  LEGAL_ID_ALREADY_EXIST = "LEGAL_ID_ALREADY_EXIST",
  ADDRESS_ALREADY_EXIST = "ADDRESS_ALREADY_EXIST",
  STORE_NOT_FOUND = "STORE_NOT_FOUND",
  CURRENCY_NOT_EXIST = "CURRENCY_NOT_EXIST",
  PRICE_LIST_NOT_EXIST = "PRICE_LIST_NOT_EXIST",
  PROMOTION_GROUP_NOT_EXIST = "PROMOTION_GROUP_NOT_EXIST",
  STOCK_GROUP_NOT_EXIST = "STOCK_GROUP_NOT_EXIST"
}

export enum DeleteStoreErrorCode {
  STORE_NOT_FOUND = "STORE_NOT_FOUND"
}

export enum DisableStoreErrorCode {
  STORE_NOT_FOUND = "STORE_NOT_FOUND"
}

export enum EnableStoreErrorCode {
  STORE_NOT_FOUND = "STORE_NOT_FOUND"
}

export enum FindStoreErrorCode {
  STORE_NOT_FOUND = "STORE_NOT_FOUND"
}

export enum FindOneStoreByInternalCodeErrorCode {
  STORE_NOT_FOUND = "STORE_NOT_FOUND"
}

export enum FindSupplierStoreErrorCode {
  INVALID_SUPPLIER_ID = "INVALID_SUPPLIER_ID",
  STORE_NOT_FOUND = "STORE_NOT_FOUND"
}

export enum UndeleteStoreErrorCode {
  STORE_NOT_FOUND = "STORE_NOT_FOUND"
}

export enum FindAllStoresErrorCode {
  INVALID_SUPPLIER_ID = "INVALID_SUPPLIER_ID"
}

export enum FindAllStoresForSalesRepErrorCode {
  INVALID_SUPPLIER_ID = "INVALID_SUPPLIER_ID",
  SALES_REP_NOT_FOUND = "SALES_REP_NOT_FOUND"
}

export enum CreateDisplayProductErrorCode {
  PRODUCT_NOT_FOUND = "PRODUCT_NOT_FOUND",
  UNITS_NOT_BE_REPEATED = "UNITS_NOT_BE_REPEATED",
  DEFAULT_NOT_BE_REPEATED = "DEFAULT_NOT_BE_REPEATED"
}

export enum FindManyProductsByIdentificationCodeErrorCode {
  PRODUCT_NOT_FOUND = "PRODUCT_NOT_FOUND"
}

export enum UpdateDisplayProductErrorCode {
  DISPLAY_NOT_FOUND = "DISPLAY_NOT_FOUND",
  PRODUCT_NOT_FOUND = "PRODUCT_NOT_FOUND"
}

export enum FindOneDisplayProductErrorCode {
  DISPLAY_NOT_FOUND = "DISPLAY_NOT_FOUND"
}
export enum FindOneDisplayProductByCodeErrorCode {
  DISPLAY_NOT_FOUND = "DISPLAY_NOT_FOUND"
}
export enum FindDisplayProductByProductIdErrorCode {
  PRODUCT_NOT_FOUND = "PRODUCT_NOT_FOUND"
}

export enum EnableDisplayProductErrorCode {
  DISPLAY_NOT_FOUND = "DISPLAY_NOT_FOUND"
}

export enum DisableDisplayProductErrorCode {
  DISPLAY_NOT_FOUND = "DISPLAY_NOT_FOUND"
}

export enum CreateLeadStoreErrorCode {
  MISSING_CONTACT_INFO = "MISSING_CONTACT_INFO"
}

export enum FindAllLeadStoresErrorCode {
  INVALID_SUPPLIER_ID = "INVALID_SUPPLIER_ID"
}

export enum ChannelType {
  PHONE = "PHONE",
  EMAIL = "EMAIL"
}

export enum CreateUserStoreErrorCode {
  INVALID_SUPPLIER_ID = "INVALID_SUPPLIER_ID",
  INVALID_STORE_ID = "INVALID_STORE_ID",
  INVALID_CHANNEL = "INVALID_CHANNEL",
  USERNAME_ALREADY_EXIST = "USERNAME_ALREADY_EXIST",
  USERNAME_SFA_ALREADY_EXIST = "USERNAME_SFA_ALREADY_EXIST"
}

export enum FindUserStoreErrorCode {
  USER_NOT_FOUND = "USER_NOT_FOUND"
}

export enum UpdateUserStoreErrorCode {
  INVALID_USERNAME = "INVALID_USERNAME"
}

export enum DeleteUserStoreErrorCode {
  INVALID_USERNAME = "INVALID_USERNAME"
}

export enum SupllierRelationNotFound {
  INVALID_SUPPLIER_ID = "INVALID_SUPPLIER_ID"
}

export enum FindAllUsersStoreErrorCode {
  STORE_NOT_FOUND = "STORE_NOT_FOUND",
  INVALID_PAGE = "INVALID_PAGE",
  INVALID_PAGE_SIZE = "INVALID_PAGE_SIZE"
}

export enum FindSupplierSettingsErrorCode {
  SUPPLIER_NOT_FOUND = "SUPPLIER_NOT_FOUND"
}

export enum CreateSupplierSettingsErrorCode {
  SUPPLIER_NOT_FOUND = "SUPPLIER_NOT_FOUND",
  PAYMENT_METHOD_NOT_FOUND = "PAYMENT_METHOD_NOT_FOUND",
  LANGUAGE_NOT_FOUND = "LANGUAGE_NOT_FOUND",
  TIMEZONE_NOT_FOUND = "TIMEZONE_NOT_FOUND"
}

export enum UpdateSupplierSettingsErrorCode {
  SUPPLIER_SETTING_NOT_FOUND = "SUPPLIER_SETTING_NOT_FOUND",
  SUPPLIER_NOT_FOUND = "SUPPLIER_NOT_FOUND",
  PAYMENT_METHOD_NOT_FOUND = "PAYMENT_METHOD_NOT_FOUND",
  LANGUAGE_NOT_FOUND = "LANGUAGE_NOT_FOUND",
  TIMEZONE_NOT_FOUND = "TIMEZONE_NOT_FOUND"
}

export enum FindStoreSettingsErrorCode {
  STORE_NOT_FOUND = "STORE_NOT_FOUND",
  USER_NOT_FOUND = "USER_NOT_FOUND"
}

export enum FindStorePreferencesErrorCode {
  STORE_NOT_FOUND = "STORE_NOT_FOUND"
}

export enum CreateStoreSettingsErrorCode {
  STORE_NOT_FOUND = "STORE_NOT_FOUND",
  STORE_SETTING_ALREADY_EXIST = "STORE_SETTING_ALREADY_EXIST",
  PAYMENT_METHOD_NOT_FOUND = "PAYMENT_METHOD_NOT_FOUND",
  LANGUAGE_NOT_FOUND = "LANGUAGE_NOT_FOUND",
  TIMEZONE_NOT_FOUND = "TIMEZONE_NOT_FOUND"
}

export enum UpsertStoreSettingsErrorCode {
  STORE_NOT_FOUND = "STORE_NOT_FOUND"
}

export enum UpdateStoreSettingsErrorCode {
  STORE_SETTING_NOT_FOUND = "STORE_SETTING_NOT_FOUND",
  STORE_NOT_FOUND = "STORE_NOT_FOUND",
  PAYMENT_METHOD_NOT_FOUND = "PAYMENT_METHOD_NOT_FOUND",
  LANGUAGE_NOT_FOUND = "LANGUAGE_NOT_FOUND",
  TIMEZONE_NOT_FOUND = "TIMEZONE_NOT_FOUND"
}

export enum CreateStorePreferencesErrorCode {
  STORE_NOT_FOUND = "STORE_NOT_FOUND",
  PAYMENT_METHOD_NOT_FOUND = "PAYMENT_METHOD_NOT_FOUND",
  LANGUAGE_NOT_FOUND = "LANGUAGE_NOT_FOUND",
  TIMEZONE_NOT_FOUND = "TIMEZONE_NOT_FOUND"
}

export enum UpdateStorePreferencesErrorCode {
  STORE_SETTING_NOT_FOUND = "STORE_SETTING_NOT_FOUND",
  PAYMENT_METHOD_NOT_FOUND = "PAYMENT_METHOD_NOT_FOUND",
  LANGUAGE_NOT_FOUND = "LANGUAGE_NOT_FOUND",
  TIMEZONE_NOT_FOUND = "TIMEZONE_NOT_FOUND"
}

export enum CreatePaymentMethodErrorCode {
  PAYMENT_METHOD_ALREADY_EXIST = "PAYMENT_METHOD_ALREADY_EXIST"
}

export enum CreateLanguageErrorCode {
  LANGUAGE_ALREADY_EXIST = "LANGUAGE_ALREADY_EXIST"
}

export enum CreateTimezoneErrorCode {
  TIMEZONE_ALREADY_EXIST = "TIMEZONE_ALREADY_EXIST"
}

export enum UpdatePaymentMethodErrorCode {
  PAYMENT_METHOD_NOT_EXIST = "PAYMENT_METHOD_NOT_EXIST",
  PAYMENT_METHOD_ALREADY_EXIST = "PAYMENT_METHOD_ALREADY_EXIST"
}

export enum UpdateLanguageErrorCode {
  LANGUAGE_NOT_EXIST = "LANGUAGE_NOT_EXIST",
  LANGUAGE_ALREADY_EXIST = "LANGUAGE_ALREADY_EXIST"
}

export enum UpdateTimezoneErrorCode {
  TIMEZONE_NOT_EXIST = "TIMEZONE_NOT_EXIST",
  TIMEZONE_ALREADY_EXIST = "TIMEZONE_ALREADY_EXIST"
}

export enum CreatePriceListErrorCode {
  SUPPLIER_NOT_FOUND = "SUPPLIER_NOT_FOUND",
  PRICE_LIST_ALREADY_EXIST = "PRICE_LIST_ALREADY_EXIST",
  INTERNAL_CODE_ALREADY_EXIST = "INTERNAL_CODE_ALREADY_EXIST"
}

export enum UpdatePriceListErrorCode {
  PRICE_LIST_NOT_FOUND = "PRICE_LIST_NOT_FOUND",
  PRICE_LIST_ALREADY_EXIST = "PRICE_LIST_ALREADY_EXIST",
  INTERNAL_CODE_ALREADY_EXIST = "INTERNAL_CODE_ALREADY_EXIST"
}

export enum AddStoreToPriceListErrorCode {
  PRICE_LIST_NOT_FOUND = "PRICE_LIST_NOT_FOUND",
  STORE_NOT_FOUND = "STORE_NOT_FOUND",
  SUPPLIER_NOT_FOUND = "SUPPLIER_NOT_FOUND"
}

export enum AddProductToPriceListErrorCode {
  PRICE_LIST_NOT_FOUND = "PRICE_LIST_NOT_FOUND",
  PRODUCT_NOT_FOUND = "PRODUCT_NOT_FOUND",
  PRODUCT_ALREADY_IN_PRICE_LIST = "PRODUCT_ALREADY_IN_PRICE_LIST",
  SUPPLIER_NOT_FOUND = "SUPPLIER_NOT_FOUND"
}

export enum UpdateProductOnPriceListErrorCode {
  PRICE_LIST_NOT_FOUND = "PRICE_LIST_NOT_FOUND",
  PRODUCT_NOT_FOUND = "PRODUCT_NOT_FOUND",
  PRODUCT_NOT_IN_PRICE_LIST = "PRODUCT_NOT_IN_PRICE_LIST"
}

export enum UpsertDisplayProductInPriceListErrorCode {
  PRICE_LIST_NOT_FOUND = "PRICE_LIST_NOT_FOUND",
  PRODUCT_NOT_FOUND = "PRODUCT_NOT_FOUND",
  DISPLAY_PRODUCT_NOT_FOUND = "DISPLAY_PRODUCT_NOT_FOUND",
  PRODUCT_NOT_IN_PRICE_LIST = "PRODUCT_NOT_IN_PRICE_LIST"
}

export enum SetPriceListStatusErrorCode {
  PRICE_LIST_NOT_FOUND = "PRICE_LIST_NOT_FOUND"
}

export enum RemoveProductFromPriceListErrorCode {
  PRICE_LIST_NOT_FOUND = "PRICE_LIST_NOT_FOUND",
  PRODUCT_NOT_FOUND = "PRODUCT_NOT_FOUND",
  PRODUCT_NOT_IN_PRICE_LIST = "PRODUCT_NOT_IN_PRICE_LIST"
}

export enum FindPriceListByStoreErrorCode {
  STORE_NOT_FOUND = "STORE_NOT_FOUND"
}

export enum FindPriceListByCodeForSupplierErrorCode {
  SUPPLIER_NOT_FOUND = "SUPPLIER_NOT_FOUND",
  PRICE_LIST_CODE_NOT_EXIST = "PRICE_LIST_CODE_NOT_EXIST"
}

export enum RemoveStoreFromPriceListErrorCode {
  PRICE_LIST_NOT_FOUND = "PRICE_LIST_NOT_FOUND",
  STORE_NOT_FOUND = "STORE_NOT_FOUND",
  STORE_NOT_IN_PRICE_LIST = "STORE_NOT_IN_PRICE_LIST"
}

export enum FindStoresInternalCodesInPriceListErrorCode {
  PRICE_LIST_NOT_FOUND = "PRICE_LIST_NOT_FOUND"
}

export enum FindProductsInPriceListErrorCode {
  PRICE_LIST_NOT_FOUND = "PRICE_LIST_NOT_FOUND"
}

export enum ListingErrorCode {
  STORE_NOT_FOUND = "STORE_NOT_FOUND",
  PRICE_LIST_NOT_FOUND = "PRICE_LIST_NOT_FOUND",
  INDEX_NOT_FOUND = "INDEX_NOT_FOUND"
}

export enum CreateOrderErrorCode {
  USER_NOT_FOUND = "USER_NOT_FOUND",
  PRODUCT_NOT_FOUND = "PRODUCT_NOT_FOUND",
  SUPPLIER_NOT_FOUND = "SUPPLIER_NOT_FOUND",
  STORE_NOT_FOUND = "STORE_NOT_FOUND",
  ADDRESS_NOT_FOUND = "ADDRESS_NOT_FOUND",
  PRICE_LIST_NOT_FOUND = "PRICE_LIST_NOT_FOUND",
  PRICE_CHANGED = "PRICE_CHANGED",
  PRICE_SUBTOTAL_CHANGED = "PRICE_SUBTOTAL_CHANGED",
  GROSS_TOTAL_PRICE_CHANGED = "GROSS_TOTAL_PRICE_CHANGED",
  NET_TOTAL_PRICE_CHANGED = "NET_TOTAL_PRICE_CHANGED",
  DISCOUNTS_APPLIED_CHANGED = "DISCOUNTS_APPLIED_CHANGED",
  REWARDED_PRODUCTS_CHANGED = "REWARDED_PRODUCTS_CHANGED",
  PAYMENT_METHOD_NOT_FOUND = "PAYMENT_METHOD_NOT_FOUND",
  FINAL_PRICE_LOWER_THAN_DROPSIZE = "FINAL_PRICE_LOWER_THAN_DROPSIZE",
  STORE_IS_DEFAULTER = "STORE_IS_DEFAULTER",
  PAYMENT_COMPOSITIONS_CODES_NOT_MATCH = "PAYMENT_COMPOSITIONS_CODES_NOT_MATCH",
  PAYMENT_COMPOSITIONS_AMOUNTS_NOT_MATCH = "PAYMENT_COMPOSITIONS_AMOUNTS_NOT_MATCH",
  SUBTOTALS_NOT_MATCH_TOTAL = "SUBTOTALS_NOT_MATCH_TOTAL",
  PAYMENT_METHOD_NOT_ALLOWED_FOR_STORE = "PAYMENT_METHOD_NOT_ALLOWED_FOR_STORE",
  PAYMENT_NOT_IN_DETAILS = "PAYMENT_NOT_IN_DETAILS",
  USER_NOT_IN_STORE = "USER_NOT_IN_STORE",
  ERROR_IN_CART_PRODUCTS = "ERROR_IN_CART_PRODUCTS",
  PROMOTION_ON_PRODUCT_CHANGED = "PROMOTION_ON_PRODUCT_CHANGED",
  PROMOTION_ON_PRODUCT_NOT_AVAILABLE = "PROMOTION_ON_PRODUCT_NOT_AVAILABLE",
  PRICE_CHANGED_AND_PROMOTION_CHANGED = "PRICE_CHANGED_AND_PROMOTION_CHANGED",
  PRICE_CHANGED_AND_PROMOTION_NOT_AVAILABLE = "PRICE_CHANGED_AND_PROMOTION_NOT_AVAILABLE",
  MULTIPLE_PROMOTION_CHANGED = "MULTIPLE_PROMOTION_CHANGED",
  PROMOTION_CHANGED = "PROMOTION_CHANGED",
  NOT_ENOUGH_STOCK = "NOT_ENOUGH_STOCK",
  NOT_ENOUGH_PROMO_STOCK = "NOT_ENOUGH_PROMO_STOCK"
}

export enum SimulateOrderErrorCode {
  USER_NOT_FOUND = "USER_NOT_FOUND",
  SUPPLIER_NOT_FOUND = "SUPPLIER_NOT_FOUND",
  STORE_NOT_FOUND = "STORE_NOT_FOUND",
  PRICE_LIST_NOT_FOUND = "PRICE_LIST_NOT_FOUND",
  PRICES_CHANGED = "PRICES_CHANGED",
  FINAL_PRICE_LOWER_THAN_DROPSIZE = "FINAL_PRICE_LOWER_THAN_DROPSIZE",
  STORE_IS_DEFAULTER = "STORE_IS_DEFAULTER",
  USER_NOT_IN_STORE = "USER_NOT_IN_STORE",
  //Errors in cart products
  ERROR_IN_CART_PRODUCTS = "ERROR_IN_CART_PRODUCTS",
  PRODUCT_NOT_FOUND = "PRODUCT_NOT_FOUND",
  PRICE_CHANGED = "PRICE_CHANGED",
  PROMOTION_CHANGED = "PROMOTION_CHANGED",
  NOT_ENOUGH_PROMO_STOCK = "NOT_ENOUGH_PROMO_STOCK"
}

export enum ConfirmOrderErrorCode {
  ORDER_NOT_FOUND = "ORDER_NOT_FOUND",
  ORDER_NOT_PENDING = "ORDER_NOT_PENDING",
  INVALID_DATE = "INVALID_DATE",
  ORDER_STATUS_INVALID = "ORDER_STATUS_INVALID"
}

export enum DeliverOrderErrorCode {
  ORDER_NOT_FOUND = "ORDER_NOT_FOUND",
  ORDER_NOT_CONFIRMED = "ORDER_NOT_CONFIRMED"
}

export enum CancelOrderErrorCode {
  ORDER_NOT_FOUND = "ORDER_NOT_FOUND",
  ORDER_CANNOT_BE_CANCELLED = "ORDER_CANNOT_BE_CANCELLED",
  REASON_NOT_FOUND = "REASON_NOT_FOUND",
  ORDER_ALREADY_DELIVERED = "ORDER_ALREADY_DELIVERED",
  USER_NOT_ALLOWED = "USER_NOT_ALLOWED"
}

export enum FindAllOrdersErrorCode {
  SUPPLIER_NOT_FOUND = "SUPPLIER_NOT_FOUND",
  STORE_NOT_PROVIDED = "STORE_NOT_PROVIDED"
}

export enum FindOrdersForSupplierErrorCode {
  SALES_REP_NOT_FOUND = "SALES_REP_NOT_FOUND"
}

export enum FindLatestOrdersErrorCode {
  PAYMENT_METHOD_NOT_FOUND = "PAYMENT_METHOD_NOT_FOUND"
}

export enum FindOrderByIdErrorCode {
  ORDER_NOT_FOUND = "ORDER_NOT_FOUND"
}

export enum FindOrderByIdForSalesRepErrorCode {
  ORDER_NOT_FOUND = "ORDER_NOT_FOUND",
  SALES_REP_NOT_FOUND = "SALES_REP_NOT_FOUND"
}

export enum GuardAuthErrorCode {
  HEADER_NOT_PROVIDED = "HEADER_NOT_PROVIDED",
  UNAUTHORIZED = "UNAUTHORIZED",
  SUPPLIER_NOT_FOUND = "SUPPLIER_NOT_FOUND",
  FORBIDDEN_RESOURCE = "FORBIDDEN_RESOURCE",
  USER_NOT_FOUND = "USER_NOT_FOUND",
  INVALID_ORIGIN_URL = "INVALID_ORIGIN_URL"
}

export enum TokenVerifyErrorCode {
  TOKEN_INVALID = "TOKEN_INVALID",
  TOKEN_EXPIRED = "TOKEN_EXPIRED"
}

export enum ScopeVerifyErrorCode {
  SCOPE_INVALID = "SCOPE_INVALID"
}

export enum RecaptchaErrorCode {
  HEADER_NOT_PROVIDED = "HEADER_NOT_PROVIDED",
  RECAPTCHA_FAIL = "RECAPTCHA_FAIL",
  RECAPTCHA_TOKEN_INVALID = "RECAPTCHA_TOKEN_INVALID",
  RECAPTCHA_ACTION_INVALID = "RECAPTCHA_ACTION_INVALID",
  RECAPTCHA_CREATE_ASSESSEMENT = "RECAPTCHA_CREATE_ASSESSEMENT"
}

export enum RecaptchaActionType {
  LOGIN = "LOGIN",
  SIGN_UP = "SIGN_UP",
  RESET_PASSWORD = "RESET_PASSWORD",
  CREATE_LEAD_STORE = "CREATE_LEAD_STORE",
  CHECK = "CHECK",
  SET_PASSWORD = "SET_PASSWORD",
  CONFIRM_FORGOT_PASSWORD = "CONFIRM_FORGOT_PASSWORD",
  FORGOT_PASSWORD = "FORGOT_PASSWORD",
  SEND_VERIFICATION_CODE = "SEND_VERIFICATION_CODE",
  REFRESH_TOKEN = "REFRESH_TOKEN"
}

export enum ChatbotUserErrorCode {
  USER_NOT_FOUND = "USER_NOT_FOUND",
  STORE_NOT_FOUND = "STORE_NOT_FOUND",
  UNAUTHORIZED = "UNAUTHORIZED",
  FALIED_CREATE_USER = "FALIED_CREATE_USER"
}

export enum IntegrationApiErrorCode {
  USER_NOT_FOUND = "USER_NOT_FOUND",
  STORE_NOT_FOUND = "STORE_NOT_FOUND",
  STORE_CREDICT_NOT_FOUND = "STORE_CREDICT_NOT_FOUND",
  FALIED_UPSERT_AMOUNT = "FALIED_UPSERT_AMOUNT",
  FALIED_UPDATE_DEFAULTER = "FALIED_UPDATE_DEFAULTER",
  STORE_UNAVAILABILITY_NOT_FOUND = "STORE_UNAVAILABILITY_NOT_FOUND",
  PRODUCT_NOT_FOUND = "PRODUCT_NOT_FOUND",
  DISPLAY_PRODUCT_NOT_FOUND = "DISPLAY_PRODUCT_NOT_FOUND",
  CATEGORY_NOT_FOUND = "CATEGORY_NOT_FOUND",
  BRAND_NOT_FOUND = "BRAND_NOT_FOUND",
  PROMOTION_NOT_FOUND = "PROMOTION_NOT_FOUND",
  PROMOTION_GROUP_NOT_FOUND = "PROMOTION_GROUP_NOT_FOUND",
  STOCK_GROUP_NOT_FOUND = "STOCK_GROUP_NOT_FOUND",
  NOT_MODIFIED = "NOT_MODIFIED",
  STORE_SETTING_NOT_FOUND = "STORE_SETTING_NOT_FOUND"
}
export enum CreateCancelReasonSupplier {
  SUPPLIER_NOT_FOUND = "SUPPLIER_NOT_FOUND",
  REASON_NOT_FOUND = "REASON_NOT_FOUND",
  REASON_ALREADY_EXIST = "REASON_ALREADY_EXIST"
}
export enum CreateCommertialRouteErrorCode {
  INTERNAL_CODE_ALREADY_EXISTS = "INTERNAL_CODE_ALREADY_EXISTS",
  SALES_REP_DOES_NOT_EXIST = "SALES_REP_DOES_NOT_EXIST",
  SALES_REP_IS_ASSIGNED_TO_ANOTHER_COMMERTIAL_ROUTE = "SALES_REP_IS_ASSIGNED_TO_ANOTHER_COMMERTIAL_ROUTE"
}
export enum UpdateCommertialRouteErrorCode {
  INTERNAL_CODE_ALREADY_EXISTS = "INTERNAL_CODE_ALREADY_EXISTS",
  SALES_REP_DOES_NOT_EXIST = "SALES_REP_DOES_NOT_EXIST",
  SALES_REP_IS_ASSIGNED_TO_ANOTHER_COMMERTIAL_ROUTE = "SALES_REP_IS_ASSIGNED_TO_ANOTHER_COMMERTIAL_ROUTE"
}
export enum AddStoreToCommertialRouteErrorCode {
  COMMERTIAL_ROUTE_NOT_FOUND = "COMMERTIAL_ROUTE_NOT_FOUND",
  STORE_NOT_FOUND = "STORE_NOT_FOUND",
  STORE_ALREADY_IN_ROUTE = "STORE_ALREADY_IN_ROUTE",
  STORE_ASSIGNED_TO_OTHER_ROUTE = "STORE_ASSIGNED_TO_OTHER_ROUTE"
}
export enum RemoveStoreFromCommertialRouteErrorCode {
  COMMERTIAL_ROUTE_NOT_FOUND = "COMMERTIAL_ROUTE_NOT_FOUND",
  STORE_NOT_FOUND = "STORE_NOT_FOUND"
}
export enum SetCommertialRouteStatusErrorCode {
  COMMERTIAL_ROUTE_NOT_FOUND = "COMMERTIAL_ROUTE_NOT_FOUND"
}

export enum RecommendationExceptionErrorCode {
  RECOMMENDATION_NOT_FOUND = "RECOMMENDATION_NOT_FOUND"
}

export enum CreateSalesRepErrorCode {
  USERNAME_ALREADY_EXIST_IN_SFA = "USERNAME_ALREADY_EXIST_IN_SFA",
  USERNAME_ALREADY_EXIST_IN_PWA = "USERNAME_ALREADY_EXIST_IN_PWA",
  INVALID_CHANNEL = "INVALID_CHANNEL",
  INVALID_COUNTRY_CODE = "INVALID_COUNTRY_CODE",
  COMMERTIAL_ROUTE_NOT_EXIST = "COMMERTIAL_ROUTE_NOT_EXIST",
  COMMERTIAL_ROUTE_ALREADY_ASSIGNED = "COMMERTIAL_ROUTE_ALREADY_ASSIGNED"
}

export enum UpdateSalesRepErrorCode {
  SALES_REP_NOT_EXIST = "SALES_REP_NOT_EXIST",
  COMMERTIAL_ROUTE_NOT_EXIST = "COMMERTIAL_ROUTE_NOT_EXIST",
  COMMERTIAL_ROUTE_ALREADY_ASSIGNED = "COMMERTIAL_ROUTE_ALREADY_ASSIGNED"
}

export enum SetSalesRepStatusErrorCode {
  SALES_REP_NOT_EXIST = "SALES_REP_NOT_EXIST"
}

export enum FindSalesRepByIdErrorCode {
  SALES_REP_NOT_EXIST = "SALES_REP_NOT_EXIST"
}

export enum FindOneCommertialRouteErrorCode {
  COMMERTIAL_ROUTE_NOT_FOUND = "COMMERTIAL_ROUTE_NOT_FOUND",
  SUPPLIER_NOT_FOUND = "SUPPLIER_NOT_FOUND"
}

export enum FindStoresInternalCodesInCommertialRouteErrorCode {
  COMMERTIAL_ROUTE_NOT_FOUND = "COMMERTIAL_ROUTE_NOT_FOUND",
  SUPPLIER_NOT_FOUND = "SUPPLIER_NOT_FOUND"
}

export enum FindCommertialRoutesErrorCode {
  SUPPLIER_NOT_FOUND = "SUPPLIER_NOT_FOUND"
}

export enum FindSupplierProfileErrorCode {
  USER_NOT_FOUND = "USER_NOT_FOUND"
}

export enum FindCartProductInfoErrorCode {
  STORE_NOT_FOUND = "STORE_NOT_FOUND",
  STORE_HAS_NO_PRICE_LIST = "STORE_HAS_NO_PRICE_LIST",
  STORE_NOT_BELONG_TO_USER = "STORE_NOT_BELONG_TO_USER"
}

export enum SupplierIntegrationErrorCode {
  SUPPLIER_INTEGRATION_NOT_FOUND = "SUPPLIER_INTEGRATION_NOT_FOUND"
}

export enum CountryCodes {
  EstadosUnidos = "+1",
  Canada = "+1",
  Mexico = "+52",
  Espana = "+34",
  Argentina = "+54",
  Brasil = "+55",
  Colombia = "+57",
  Peru = "+51",
  Chile = "+56",
  Venezuela = "+58",
  Uruguay = "+598",
  Paraguay = "+595",
  Ecuador = "+593",
  Bolivia = "+591",
  Panama = "+507",
  CostaRica = "+506",
  RepublicaDominicana = "+1-809",
  PuertoRico = "+1-787",
  Guatemala = "+502",
  ElSalvador = "+503",
  Honduras = "+504",
  Nicaragua = "+505",
  Cuba = "+53",
  Jamaica = "+1-876",
  TrinidadYTobago = "+1-868",
  Barbados = "+1-246",
  Bahamas = "+1-242",
  Haiti = "+509",
  RepublicaCheca = "+420",
  Alemania = "+49",
  Francia = "+33",
  Italia = "+39",
  ReinoUnido = "+44",
  Japon = "+81",
  Australia = "+61",
  NuevaZelanda = "+64",
  China = "+86",
  India = "+91",
  Sudafrica = "+27",
  Nigeria = "+234",
  Kenya = "+254",
  Egipto = "+20",
  Turquia = "+90",
  Rusia = "+7",
  ArabiaSaudita = "+966",
  EmiratosArabesUnidos = "+971",
  Singapur = "+65",
  CoreaDelSur = "+82",
  Malasia = "+60",
  Tailandia = "+66",
  Vietnam = "+84",
  Filipinas = "+63",

  // Nuevos países de América
  AntiguaYBarbuda = "+1-268",
  Belize = "+501",
  Dominica = "+1-767",
  Granada = "+1-473",
  Guyana = "+592",
  SaintKittsYNevis = "+1-869",
  SanVicenteYGrenadinas = "+1-784",
  Surinam = "+597",
  IslaDelPrincipeEduardo = "+1-902",
  Yukon = "+1-867",
  TerranovaYLabrador = "+1-709",
  TerritoriosDelNoroeste = "+1-867",
  Nunavut = "+1-867",
  ColumbiaBritanica = "+1-250",
  Alberta = "+1-403",
  Saskatchewan = "+1-306",
  Manitoba = "+1-204",
  Ontario = "+1-416",
  Quebec = "+1-418",
  NuevoBrunswick = "+1-506",
  NuevaEscocia = "+1-902",
  IslaDeVancouver = "+1-604"
}

export enum COOKIES {
  session = "SESSION",
  siteConfiguration = "SITE_CONFIGURATION",
  locale = "NEXT_LOCALE",
  uuid = "UUID",
  version = "VERSION"
}

export enum LOCAL_STORAGE {
  terms = "agreeTerms",
  platform = "platform"
}

export const COGNITO_USERNAME = "cognito:username";

export const DateFormat = "dd/MM/yyyy";

export enum PaymentMethods {
  CRED = "CRED",
  CASH = "CASH",
  BNPL = "BNPL",
  DEFINED_AT_DELIVERY = "DEFINED_AT_DELIVERY"
}

export const FAKE_ID_TO_AVOID_PRISMA_ERROR = 0.2;

export const qp = { REDIRECT: "redirect", NO_DATA: "null" };
