import { getLocale } from "../utils";

export const redirectNoAuth = `/${getLocale()}/login`;

export const publicPaths = [
  /^\/$/,
  /^\/(es|en|es-HN|es-AR|es-MX)$/,
  /^\/(es|en|es-HN|es-AR|es-MX)\/register$/,
  /^\/(es|en|es-HN|es-AR|es-MX)\/login(\/.*)?$/
];
