import * as messagesEs from "@i18n-messages/es.json";
import * as messagesEn from "@i18n-messages/en.json";
import * as messagesEsHN from "@i18n-messages/es-HN.json";
import * as messagesEsAR from "@i18n-messages/es-AR.json";
import * as messagesEsMx from "@i18n-messages/es-MX.json";

import { localesType, lang } from "../constants";
import { AbstractIntlMessages } from "next-intl";
import { deepMerge } from "../utils/merge";

export const messages = (locale) => {
  switch (locale) {
    case lang.en:
      return messagesEn;
    case lang.esMX:
      return deepMerge({ ...messagesEs }, messagesEsMx);
    case lang.esAR:
      return deepMerge({ ...messagesEs }, messagesEsAR);
    case lang.esHN:
      return deepMerge({ ...messagesEs }, messagesEsHN);
    default:
      return messagesEs;
  }
};

export const getMessages = function (locale: localesType) {
  return messages(locale) as unknown as AbstractIntlMessages;
};
