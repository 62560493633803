import type * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FindRecommendationsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type FindRecommendationsQuery = { __typename?: 'Query', findRecommendations: Array<{ __typename?: 'AdminRecommendation', id: number, title: string, order?: number | null, internalCode: string, products?: number | null, stores?: number | null, type: Types.RecommendationType, enabled: boolean, description?: string | null, startDate?: string | null, endDate?: string | null }> };

export type FindRecommendationQueryVariables = Types.Exact<{
  id: Types.Scalars['Int'];
}>;


export type FindRecommendationQuery = { __typename?: 'Query', findRecommendation: { __typename?: 'AdminRecommendation', id: number, title: string, order?: number | null, internalCode: string, products?: number | null, stores?: number | null, type: Types.RecommendationType, enabled: boolean, description?: string | null, startDate?: string | null, endDate?: string | null } };

export type FindStoresInRecommendationQueryVariables = Types.Exact<{
  id: Types.Scalars['Int'];
}>;


export type FindStoresInRecommendationQuery = { __typename?: 'Query', findStoresInRecommendation: Array<{ __typename?: 'StoreInRecommendation', id: number, internalCode: string }> };

export type CreateRecommendationMutationVariables = Types.Exact<{
  input: Types.CreateRecommendationInput;
}>;


export type CreateRecommendationMutation = { __typename?: 'Mutation', createRecommendation: boolean };

export type UpdateRecommendationMutationVariables = Types.Exact<{
  input: Types.UpdateRecommendationInput;
}>;


export type UpdateRecommendationMutation = { __typename?: 'Mutation', updateRecommendation: boolean };

export type SetRecommendationStatusMutationVariables = Types.Exact<{
  input: Types.SetRecommendationStatusInput;
}>;


export type SetRecommendationStatusMutation = { __typename?: 'Mutation', setRecommendationStatus: boolean };

export type UpdateRecommendationOrderMutationVariables = Types.Exact<{
  input: Types.UpdateRecommendationOrderInput;
}>;


export type UpdateRecommendationOrderMutation = { __typename?: 'Mutation', updateRecommendationOrder: boolean };

export type AddStoreToRecommendationMutationVariables = Types.Exact<{
  input: Types.AddOrRemoveStoreToRecommendationInput;
}>;


export type AddStoreToRecommendationMutation = { __typename?: 'Mutation', addStoreToRecommendation: boolean };

export type RemoveStoreFromRecommendationMutationVariables = Types.Exact<{
  input: Types.AddOrRemoveStoreToRecommendationInput;
}>;


export type RemoveStoreFromRecommendationMutation = { __typename?: 'Mutation', removeStoreFromRecommendation: boolean };

export type FindSupplierRecommendationPrioritiesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type FindSupplierRecommendationPrioritiesQuery = { __typename?: 'Query', findSupplierRecommendationPriorities: { __typename?: 'ItemInRecommendation', products: Array<{ __typename?: 'ProductInRecommendation', id: number, order?: number | null, title: string, image: string, brand: string, category: string, internalCode: string }>, brands: Array<{ __typename?: 'BrandInRecommendation', id: number, order?: number | null, name: string }>, categories: Array<{ __typename?: 'CategoryInRecommendation', id: number, order?: number | null, name: string }> } };

export type FindSupplierRecommendationRestrictionsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type FindSupplierRecommendationRestrictionsQuery = { __typename?: 'Query', findSupplierRecommendationRestrictions: { __typename?: 'ItemInRecommendation', products: Array<{ __typename?: 'ProductInRecommendation', id: number, order?: number | null, title: string, image: string, brand: string, category: string, internalCode: string }>, brands: Array<{ __typename?: 'BrandInRecommendation', id: number, order?: number | null, name: string }>, categories: Array<{ __typename?: 'CategoryInRecommendation', id: number, order?: number | null, name: string }> } };

export type AddSupplierRestrictedProductMutationVariables = Types.Exact<{
  internalCode: Types.Scalars['String'];
}>;


export type AddSupplierRestrictedProductMutation = { __typename?: 'Mutation', addSupplierRestrictedProduct: boolean };

export type AddSupplierRestrictedBrandMutationVariables = Types.Exact<{
  internalCode: Types.Scalars['String'];
}>;


export type AddSupplierRestrictedBrandMutation = { __typename?: 'Mutation', addSupplierRestrictedBrand: boolean };

export type AddSupplierRestrictedCategoryMutationVariables = Types.Exact<{
  internalCode: Types.Scalars['String'];
}>;


export type AddSupplierRestrictedCategoryMutation = { __typename?: 'Mutation', addSupplierRestrictedCategory: boolean };

export type RemoveSupplierRestrictedProductMutationVariables = Types.Exact<{
  internalCode: Types.Scalars['String'];
}>;


export type RemoveSupplierRestrictedProductMutation = { __typename?: 'Mutation', removeSupplierRestrictedProduct: boolean };

export type RemoveSupplierRestrictedBrandMutationVariables = Types.Exact<{
  internalCode: Types.Scalars['String'];
}>;


export type RemoveSupplierRestrictedBrandMutation = { __typename?: 'Mutation', removeSupplierRestrictedBrand: boolean };

export type RemoveSupplierRestrictedCategoryMutationVariables = Types.Exact<{
  internalCode: Types.Scalars['String'];
}>;


export type RemoveSupplierRestrictedCategoryMutation = { __typename?: 'Mutation', removeSupplierRestrictedCategory: boolean };

export type AddSupplierPrioritizedProductMutationVariables = Types.Exact<{
  internalCode: Types.Scalars['String'];
}>;


export type AddSupplierPrioritizedProductMutation = { __typename?: 'Mutation', addSupplierPrioritizedProduct: boolean };

export type AddSupplierPrioritizedBrandMutationVariables = Types.Exact<{
  internalCode: Types.Scalars['String'];
}>;


export type AddSupplierPrioritizedBrandMutation = { __typename?: 'Mutation', addSupplierPrioritizedBrand: boolean };

export type AddSupplierPrioritizedCategoryMutationVariables = Types.Exact<{
  internalCode: Types.Scalars['String'];
}>;


export type AddSupplierPrioritizedCategoryMutation = { __typename?: 'Mutation', addSupplierPrioritizedCategory: boolean };

export type RemoveSupplierPrioritizedProductMutationVariables = Types.Exact<{
  internalCode: Types.Scalars['String'];
}>;


export type RemoveSupplierPrioritizedProductMutation = { __typename?: 'Mutation', removeSupplierPrioritizedProduct: boolean };

export type RemoveSupplierPrioritizedBrandMutationVariables = Types.Exact<{
  internalCode: Types.Scalars['String'];
}>;


export type RemoveSupplierPrioritizedBrandMutation = { __typename?: 'Mutation', removeSupplierPrioritizedBrand: boolean };

export type RemoveSupplierPrioritizedCategoryMutationVariables = Types.Exact<{
  internalCode: Types.Scalars['String'];
}>;


export type RemoveSupplierPrioritizedCategoryMutation = { __typename?: 'Mutation', removeSupplierPrioritizedCategory: boolean };


export const FindRecommendationsDocument = gql`
    query FindRecommendations {
  findRecommendations {
    id
    title
    order
    internalCode
    products
    stores
    type
    enabled
    description
    startDate
    endDate
  }
}
    `;

/**
 * __useFindRecommendationsQuery__
 *
 * To run a query within a React component, call `useFindRecommendationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindRecommendationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindRecommendationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useFindRecommendationsQuery(baseOptions?: Apollo.QueryHookOptions<FindRecommendationsQuery, FindRecommendationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindRecommendationsQuery, FindRecommendationsQueryVariables>(FindRecommendationsDocument, options);
      }
export function useFindRecommendationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindRecommendationsQuery, FindRecommendationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindRecommendationsQuery, FindRecommendationsQueryVariables>(FindRecommendationsDocument, options);
        }
export type FindRecommendationsQueryHookResult = ReturnType<typeof useFindRecommendationsQuery>;
export type FindRecommendationsLazyQueryHookResult = ReturnType<typeof useFindRecommendationsLazyQuery>;
export type FindRecommendationsQueryResult = Apollo.QueryResult<FindRecommendationsQuery, FindRecommendationsQueryVariables>;
export const FindRecommendationDocument = gql`
    query FindRecommendation($id: Int!) {
  findRecommendation(id: $id) {
    id
    title
    order
    internalCode
    products
    stores
    type
    enabled
    description
    startDate
    endDate
  }
}
    `;

/**
 * __useFindRecommendationQuery__
 *
 * To run a query within a React component, call `useFindRecommendationQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindRecommendationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindRecommendationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFindRecommendationQuery(baseOptions: Apollo.QueryHookOptions<FindRecommendationQuery, FindRecommendationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindRecommendationQuery, FindRecommendationQueryVariables>(FindRecommendationDocument, options);
      }
export function useFindRecommendationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindRecommendationQuery, FindRecommendationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindRecommendationQuery, FindRecommendationQueryVariables>(FindRecommendationDocument, options);
        }
export type FindRecommendationQueryHookResult = ReturnType<typeof useFindRecommendationQuery>;
export type FindRecommendationLazyQueryHookResult = ReturnType<typeof useFindRecommendationLazyQuery>;
export type FindRecommendationQueryResult = Apollo.QueryResult<FindRecommendationQuery, FindRecommendationQueryVariables>;
export const FindStoresInRecommendationDocument = gql`
    query FindStoresInRecommendation($id: Int!) {
  findStoresInRecommendation(id: $id) {
    id
    internalCode
  }
}
    `;

/**
 * __useFindStoresInRecommendationQuery__
 *
 * To run a query within a React component, call `useFindStoresInRecommendationQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindStoresInRecommendationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindStoresInRecommendationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFindStoresInRecommendationQuery(baseOptions: Apollo.QueryHookOptions<FindStoresInRecommendationQuery, FindStoresInRecommendationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindStoresInRecommendationQuery, FindStoresInRecommendationQueryVariables>(FindStoresInRecommendationDocument, options);
      }
export function useFindStoresInRecommendationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindStoresInRecommendationQuery, FindStoresInRecommendationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindStoresInRecommendationQuery, FindStoresInRecommendationQueryVariables>(FindStoresInRecommendationDocument, options);
        }
export type FindStoresInRecommendationQueryHookResult = ReturnType<typeof useFindStoresInRecommendationQuery>;
export type FindStoresInRecommendationLazyQueryHookResult = ReturnType<typeof useFindStoresInRecommendationLazyQuery>;
export type FindStoresInRecommendationQueryResult = Apollo.QueryResult<FindStoresInRecommendationQuery, FindStoresInRecommendationQueryVariables>;
export const CreateRecommendationDocument = gql`
    mutation CreateRecommendation($input: CreateRecommendationInput!) {
  createRecommendation(input: $input)
}
    `;
export type CreateRecommendationMutationFn = Apollo.MutationFunction<CreateRecommendationMutation, CreateRecommendationMutationVariables>;

/**
 * __useCreateRecommendationMutation__
 *
 * To run a mutation, you first call `useCreateRecommendationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRecommendationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRecommendationMutation, { data, loading, error }] = useCreateRecommendationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateRecommendationMutation(baseOptions?: Apollo.MutationHookOptions<CreateRecommendationMutation, CreateRecommendationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateRecommendationMutation, CreateRecommendationMutationVariables>(CreateRecommendationDocument, options);
      }
export type CreateRecommendationMutationHookResult = ReturnType<typeof useCreateRecommendationMutation>;
export type CreateRecommendationMutationResult = Apollo.MutationResult<CreateRecommendationMutation>;
export type CreateRecommendationMutationOptions = Apollo.BaseMutationOptions<CreateRecommendationMutation, CreateRecommendationMutationVariables>;
export const UpdateRecommendationDocument = gql`
    mutation UpdateRecommendation($input: UpdateRecommendationInput!) {
  updateRecommendation(input: $input)
}
    `;
export type UpdateRecommendationMutationFn = Apollo.MutationFunction<UpdateRecommendationMutation, UpdateRecommendationMutationVariables>;

/**
 * __useUpdateRecommendationMutation__
 *
 * To run a mutation, you first call `useUpdateRecommendationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRecommendationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRecommendationMutation, { data, loading, error }] = useUpdateRecommendationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateRecommendationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateRecommendationMutation, UpdateRecommendationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateRecommendationMutation, UpdateRecommendationMutationVariables>(UpdateRecommendationDocument, options);
      }
export type UpdateRecommendationMutationHookResult = ReturnType<typeof useUpdateRecommendationMutation>;
export type UpdateRecommendationMutationResult = Apollo.MutationResult<UpdateRecommendationMutation>;
export type UpdateRecommendationMutationOptions = Apollo.BaseMutationOptions<UpdateRecommendationMutation, UpdateRecommendationMutationVariables>;
export const SetRecommendationStatusDocument = gql`
    mutation SetRecommendationStatus($input: SetRecommendationStatusInput!) {
  setRecommendationStatus(input: $input)
}
    `;
export type SetRecommendationStatusMutationFn = Apollo.MutationFunction<SetRecommendationStatusMutation, SetRecommendationStatusMutationVariables>;

/**
 * __useSetRecommendationStatusMutation__
 *
 * To run a mutation, you first call `useSetRecommendationStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetRecommendationStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setRecommendationStatusMutation, { data, loading, error }] = useSetRecommendationStatusMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetRecommendationStatusMutation(baseOptions?: Apollo.MutationHookOptions<SetRecommendationStatusMutation, SetRecommendationStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetRecommendationStatusMutation, SetRecommendationStatusMutationVariables>(SetRecommendationStatusDocument, options);
      }
export type SetRecommendationStatusMutationHookResult = ReturnType<typeof useSetRecommendationStatusMutation>;
export type SetRecommendationStatusMutationResult = Apollo.MutationResult<SetRecommendationStatusMutation>;
export type SetRecommendationStatusMutationOptions = Apollo.BaseMutationOptions<SetRecommendationStatusMutation, SetRecommendationStatusMutationVariables>;
export const UpdateRecommendationOrderDocument = gql`
    mutation UpdateRecommendationOrder($input: UpdateRecommendationOrderInput!) {
  updateRecommendationOrder(input: $input)
}
    `;
export type UpdateRecommendationOrderMutationFn = Apollo.MutationFunction<UpdateRecommendationOrderMutation, UpdateRecommendationOrderMutationVariables>;

/**
 * __useUpdateRecommendationOrderMutation__
 *
 * To run a mutation, you first call `useUpdateRecommendationOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRecommendationOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRecommendationOrderMutation, { data, loading, error }] = useUpdateRecommendationOrderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateRecommendationOrderMutation(baseOptions?: Apollo.MutationHookOptions<UpdateRecommendationOrderMutation, UpdateRecommendationOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateRecommendationOrderMutation, UpdateRecommendationOrderMutationVariables>(UpdateRecommendationOrderDocument, options);
      }
export type UpdateRecommendationOrderMutationHookResult = ReturnType<typeof useUpdateRecommendationOrderMutation>;
export type UpdateRecommendationOrderMutationResult = Apollo.MutationResult<UpdateRecommendationOrderMutation>;
export type UpdateRecommendationOrderMutationOptions = Apollo.BaseMutationOptions<UpdateRecommendationOrderMutation, UpdateRecommendationOrderMutationVariables>;
export const AddStoreToRecommendationDocument = gql`
    mutation AddStoreToRecommendation($input: AddOrRemoveStoreToRecommendationInput!) {
  addStoreToRecommendation(input: $input)
}
    `;
export type AddStoreToRecommendationMutationFn = Apollo.MutationFunction<AddStoreToRecommendationMutation, AddStoreToRecommendationMutationVariables>;

/**
 * __useAddStoreToRecommendationMutation__
 *
 * To run a mutation, you first call `useAddStoreToRecommendationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddStoreToRecommendationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addStoreToRecommendationMutation, { data, loading, error }] = useAddStoreToRecommendationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddStoreToRecommendationMutation(baseOptions?: Apollo.MutationHookOptions<AddStoreToRecommendationMutation, AddStoreToRecommendationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddStoreToRecommendationMutation, AddStoreToRecommendationMutationVariables>(AddStoreToRecommendationDocument, options);
      }
export type AddStoreToRecommendationMutationHookResult = ReturnType<typeof useAddStoreToRecommendationMutation>;
export type AddStoreToRecommendationMutationResult = Apollo.MutationResult<AddStoreToRecommendationMutation>;
export type AddStoreToRecommendationMutationOptions = Apollo.BaseMutationOptions<AddStoreToRecommendationMutation, AddStoreToRecommendationMutationVariables>;
export const RemoveStoreFromRecommendationDocument = gql`
    mutation RemoveStoreFromRecommendation($input: AddOrRemoveStoreToRecommendationInput!) {
  removeStoreFromRecommendation(input: $input)
}
    `;
export type RemoveStoreFromRecommendationMutationFn = Apollo.MutationFunction<RemoveStoreFromRecommendationMutation, RemoveStoreFromRecommendationMutationVariables>;

/**
 * __useRemoveStoreFromRecommendationMutation__
 *
 * To run a mutation, you first call `useRemoveStoreFromRecommendationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveStoreFromRecommendationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeStoreFromRecommendationMutation, { data, loading, error }] = useRemoveStoreFromRecommendationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveStoreFromRecommendationMutation(baseOptions?: Apollo.MutationHookOptions<RemoveStoreFromRecommendationMutation, RemoveStoreFromRecommendationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveStoreFromRecommendationMutation, RemoveStoreFromRecommendationMutationVariables>(RemoveStoreFromRecommendationDocument, options);
      }
export type RemoveStoreFromRecommendationMutationHookResult = ReturnType<typeof useRemoveStoreFromRecommendationMutation>;
export type RemoveStoreFromRecommendationMutationResult = Apollo.MutationResult<RemoveStoreFromRecommendationMutation>;
export type RemoveStoreFromRecommendationMutationOptions = Apollo.BaseMutationOptions<RemoveStoreFromRecommendationMutation, RemoveStoreFromRecommendationMutationVariables>;
export const FindSupplierRecommendationPrioritiesDocument = gql`
    query FindSupplierRecommendationPriorities {
  findSupplierRecommendationPriorities {
    products {
      id
      order
      title
      image
      brand
      category
      internalCode
    }
    brands {
      id
      order
      name
    }
    categories {
      id
      order
      name
    }
  }
}
    `;

/**
 * __useFindSupplierRecommendationPrioritiesQuery__
 *
 * To run a query within a React component, call `useFindSupplierRecommendationPrioritiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindSupplierRecommendationPrioritiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindSupplierRecommendationPrioritiesQuery({
 *   variables: {
 *   },
 * });
 */
export function useFindSupplierRecommendationPrioritiesQuery(baseOptions?: Apollo.QueryHookOptions<FindSupplierRecommendationPrioritiesQuery, FindSupplierRecommendationPrioritiesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindSupplierRecommendationPrioritiesQuery, FindSupplierRecommendationPrioritiesQueryVariables>(FindSupplierRecommendationPrioritiesDocument, options);
      }
export function useFindSupplierRecommendationPrioritiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindSupplierRecommendationPrioritiesQuery, FindSupplierRecommendationPrioritiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindSupplierRecommendationPrioritiesQuery, FindSupplierRecommendationPrioritiesQueryVariables>(FindSupplierRecommendationPrioritiesDocument, options);
        }
export type FindSupplierRecommendationPrioritiesQueryHookResult = ReturnType<typeof useFindSupplierRecommendationPrioritiesQuery>;
export type FindSupplierRecommendationPrioritiesLazyQueryHookResult = ReturnType<typeof useFindSupplierRecommendationPrioritiesLazyQuery>;
export type FindSupplierRecommendationPrioritiesQueryResult = Apollo.QueryResult<FindSupplierRecommendationPrioritiesQuery, FindSupplierRecommendationPrioritiesQueryVariables>;
export const FindSupplierRecommendationRestrictionsDocument = gql`
    query FindSupplierRecommendationRestrictions {
  findSupplierRecommendationRestrictions {
    products {
      id
      order
      title
      image
      brand
      category
      internalCode
    }
    brands {
      id
      order
      name
    }
    categories {
      id
      order
      name
    }
  }
}
    `;

/**
 * __useFindSupplierRecommendationRestrictionsQuery__
 *
 * To run a query within a React component, call `useFindSupplierRecommendationRestrictionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindSupplierRecommendationRestrictionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindSupplierRecommendationRestrictionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useFindSupplierRecommendationRestrictionsQuery(baseOptions?: Apollo.QueryHookOptions<FindSupplierRecommendationRestrictionsQuery, FindSupplierRecommendationRestrictionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindSupplierRecommendationRestrictionsQuery, FindSupplierRecommendationRestrictionsQueryVariables>(FindSupplierRecommendationRestrictionsDocument, options);
      }
export function useFindSupplierRecommendationRestrictionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindSupplierRecommendationRestrictionsQuery, FindSupplierRecommendationRestrictionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindSupplierRecommendationRestrictionsQuery, FindSupplierRecommendationRestrictionsQueryVariables>(FindSupplierRecommendationRestrictionsDocument, options);
        }
export type FindSupplierRecommendationRestrictionsQueryHookResult = ReturnType<typeof useFindSupplierRecommendationRestrictionsQuery>;
export type FindSupplierRecommendationRestrictionsLazyQueryHookResult = ReturnType<typeof useFindSupplierRecommendationRestrictionsLazyQuery>;
export type FindSupplierRecommendationRestrictionsQueryResult = Apollo.QueryResult<FindSupplierRecommendationRestrictionsQuery, FindSupplierRecommendationRestrictionsQueryVariables>;
export const AddSupplierRestrictedProductDocument = gql`
    mutation AddSupplierRestrictedProduct($internalCode: String!) {
  addSupplierRestrictedProduct(internalCode: $internalCode)
}
    `;
export type AddSupplierRestrictedProductMutationFn = Apollo.MutationFunction<AddSupplierRestrictedProductMutation, AddSupplierRestrictedProductMutationVariables>;

/**
 * __useAddSupplierRestrictedProductMutation__
 *
 * To run a mutation, you first call `useAddSupplierRestrictedProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddSupplierRestrictedProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addSupplierRestrictedProductMutation, { data, loading, error }] = useAddSupplierRestrictedProductMutation({
 *   variables: {
 *      internalCode: // value for 'internalCode'
 *   },
 * });
 */
export function useAddSupplierRestrictedProductMutation(baseOptions?: Apollo.MutationHookOptions<AddSupplierRestrictedProductMutation, AddSupplierRestrictedProductMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddSupplierRestrictedProductMutation, AddSupplierRestrictedProductMutationVariables>(AddSupplierRestrictedProductDocument, options);
      }
export type AddSupplierRestrictedProductMutationHookResult = ReturnType<typeof useAddSupplierRestrictedProductMutation>;
export type AddSupplierRestrictedProductMutationResult = Apollo.MutationResult<AddSupplierRestrictedProductMutation>;
export type AddSupplierRestrictedProductMutationOptions = Apollo.BaseMutationOptions<AddSupplierRestrictedProductMutation, AddSupplierRestrictedProductMutationVariables>;
export const AddSupplierRestrictedBrandDocument = gql`
    mutation AddSupplierRestrictedBrand($internalCode: String!) {
  addSupplierRestrictedBrand(internalCode: $internalCode)
}
    `;
export type AddSupplierRestrictedBrandMutationFn = Apollo.MutationFunction<AddSupplierRestrictedBrandMutation, AddSupplierRestrictedBrandMutationVariables>;

/**
 * __useAddSupplierRestrictedBrandMutation__
 *
 * To run a mutation, you first call `useAddSupplierRestrictedBrandMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddSupplierRestrictedBrandMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addSupplierRestrictedBrandMutation, { data, loading, error }] = useAddSupplierRestrictedBrandMutation({
 *   variables: {
 *      internalCode: // value for 'internalCode'
 *   },
 * });
 */
export function useAddSupplierRestrictedBrandMutation(baseOptions?: Apollo.MutationHookOptions<AddSupplierRestrictedBrandMutation, AddSupplierRestrictedBrandMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddSupplierRestrictedBrandMutation, AddSupplierRestrictedBrandMutationVariables>(AddSupplierRestrictedBrandDocument, options);
      }
export type AddSupplierRestrictedBrandMutationHookResult = ReturnType<typeof useAddSupplierRestrictedBrandMutation>;
export type AddSupplierRestrictedBrandMutationResult = Apollo.MutationResult<AddSupplierRestrictedBrandMutation>;
export type AddSupplierRestrictedBrandMutationOptions = Apollo.BaseMutationOptions<AddSupplierRestrictedBrandMutation, AddSupplierRestrictedBrandMutationVariables>;
export const AddSupplierRestrictedCategoryDocument = gql`
    mutation AddSupplierRestrictedCategory($internalCode: String!) {
  addSupplierRestrictedCategory(internalCode: $internalCode)
}
    `;
export type AddSupplierRestrictedCategoryMutationFn = Apollo.MutationFunction<AddSupplierRestrictedCategoryMutation, AddSupplierRestrictedCategoryMutationVariables>;

/**
 * __useAddSupplierRestrictedCategoryMutation__
 *
 * To run a mutation, you first call `useAddSupplierRestrictedCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddSupplierRestrictedCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addSupplierRestrictedCategoryMutation, { data, loading, error }] = useAddSupplierRestrictedCategoryMutation({
 *   variables: {
 *      internalCode: // value for 'internalCode'
 *   },
 * });
 */
export function useAddSupplierRestrictedCategoryMutation(baseOptions?: Apollo.MutationHookOptions<AddSupplierRestrictedCategoryMutation, AddSupplierRestrictedCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddSupplierRestrictedCategoryMutation, AddSupplierRestrictedCategoryMutationVariables>(AddSupplierRestrictedCategoryDocument, options);
      }
export type AddSupplierRestrictedCategoryMutationHookResult = ReturnType<typeof useAddSupplierRestrictedCategoryMutation>;
export type AddSupplierRestrictedCategoryMutationResult = Apollo.MutationResult<AddSupplierRestrictedCategoryMutation>;
export type AddSupplierRestrictedCategoryMutationOptions = Apollo.BaseMutationOptions<AddSupplierRestrictedCategoryMutation, AddSupplierRestrictedCategoryMutationVariables>;
export const RemoveSupplierRestrictedProductDocument = gql`
    mutation RemoveSupplierRestrictedProduct($internalCode: String!) {
  removeSupplierRestrictedProduct(internalCode: $internalCode)
}
    `;
export type RemoveSupplierRestrictedProductMutationFn = Apollo.MutationFunction<RemoveSupplierRestrictedProductMutation, RemoveSupplierRestrictedProductMutationVariables>;

/**
 * __useRemoveSupplierRestrictedProductMutation__
 *
 * To run a mutation, you first call `useRemoveSupplierRestrictedProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveSupplierRestrictedProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeSupplierRestrictedProductMutation, { data, loading, error }] = useRemoveSupplierRestrictedProductMutation({
 *   variables: {
 *      internalCode: // value for 'internalCode'
 *   },
 * });
 */
export function useRemoveSupplierRestrictedProductMutation(baseOptions?: Apollo.MutationHookOptions<RemoveSupplierRestrictedProductMutation, RemoveSupplierRestrictedProductMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveSupplierRestrictedProductMutation, RemoveSupplierRestrictedProductMutationVariables>(RemoveSupplierRestrictedProductDocument, options);
      }
export type RemoveSupplierRestrictedProductMutationHookResult = ReturnType<typeof useRemoveSupplierRestrictedProductMutation>;
export type RemoveSupplierRestrictedProductMutationResult = Apollo.MutationResult<RemoveSupplierRestrictedProductMutation>;
export type RemoveSupplierRestrictedProductMutationOptions = Apollo.BaseMutationOptions<RemoveSupplierRestrictedProductMutation, RemoveSupplierRestrictedProductMutationVariables>;
export const RemoveSupplierRestrictedBrandDocument = gql`
    mutation RemoveSupplierRestrictedBrand($internalCode: String!) {
  removeSupplierRestrictedBrand(internalCode: $internalCode)
}
    `;
export type RemoveSupplierRestrictedBrandMutationFn = Apollo.MutationFunction<RemoveSupplierRestrictedBrandMutation, RemoveSupplierRestrictedBrandMutationVariables>;

/**
 * __useRemoveSupplierRestrictedBrandMutation__
 *
 * To run a mutation, you first call `useRemoveSupplierRestrictedBrandMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveSupplierRestrictedBrandMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeSupplierRestrictedBrandMutation, { data, loading, error }] = useRemoveSupplierRestrictedBrandMutation({
 *   variables: {
 *      internalCode: // value for 'internalCode'
 *   },
 * });
 */
export function useRemoveSupplierRestrictedBrandMutation(baseOptions?: Apollo.MutationHookOptions<RemoveSupplierRestrictedBrandMutation, RemoveSupplierRestrictedBrandMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveSupplierRestrictedBrandMutation, RemoveSupplierRestrictedBrandMutationVariables>(RemoveSupplierRestrictedBrandDocument, options);
      }
export type RemoveSupplierRestrictedBrandMutationHookResult = ReturnType<typeof useRemoveSupplierRestrictedBrandMutation>;
export type RemoveSupplierRestrictedBrandMutationResult = Apollo.MutationResult<RemoveSupplierRestrictedBrandMutation>;
export type RemoveSupplierRestrictedBrandMutationOptions = Apollo.BaseMutationOptions<RemoveSupplierRestrictedBrandMutation, RemoveSupplierRestrictedBrandMutationVariables>;
export const RemoveSupplierRestrictedCategoryDocument = gql`
    mutation RemoveSupplierRestrictedCategory($internalCode: String!) {
  removeSupplierRestrictedCategory(internalCode: $internalCode)
}
    `;
export type RemoveSupplierRestrictedCategoryMutationFn = Apollo.MutationFunction<RemoveSupplierRestrictedCategoryMutation, RemoveSupplierRestrictedCategoryMutationVariables>;

/**
 * __useRemoveSupplierRestrictedCategoryMutation__
 *
 * To run a mutation, you first call `useRemoveSupplierRestrictedCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveSupplierRestrictedCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeSupplierRestrictedCategoryMutation, { data, loading, error }] = useRemoveSupplierRestrictedCategoryMutation({
 *   variables: {
 *      internalCode: // value for 'internalCode'
 *   },
 * });
 */
export function useRemoveSupplierRestrictedCategoryMutation(baseOptions?: Apollo.MutationHookOptions<RemoveSupplierRestrictedCategoryMutation, RemoveSupplierRestrictedCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveSupplierRestrictedCategoryMutation, RemoveSupplierRestrictedCategoryMutationVariables>(RemoveSupplierRestrictedCategoryDocument, options);
      }
export type RemoveSupplierRestrictedCategoryMutationHookResult = ReturnType<typeof useRemoveSupplierRestrictedCategoryMutation>;
export type RemoveSupplierRestrictedCategoryMutationResult = Apollo.MutationResult<RemoveSupplierRestrictedCategoryMutation>;
export type RemoveSupplierRestrictedCategoryMutationOptions = Apollo.BaseMutationOptions<RemoveSupplierRestrictedCategoryMutation, RemoveSupplierRestrictedCategoryMutationVariables>;
export const AddSupplierPrioritizedProductDocument = gql`
    mutation AddSupplierPrioritizedProduct($internalCode: String!) {
  addSupplierPrioritizedProduct(internalCode: $internalCode)
}
    `;
export type AddSupplierPrioritizedProductMutationFn = Apollo.MutationFunction<AddSupplierPrioritizedProductMutation, AddSupplierPrioritizedProductMutationVariables>;

/**
 * __useAddSupplierPrioritizedProductMutation__
 *
 * To run a mutation, you first call `useAddSupplierPrioritizedProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddSupplierPrioritizedProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addSupplierPrioritizedProductMutation, { data, loading, error }] = useAddSupplierPrioritizedProductMutation({
 *   variables: {
 *      internalCode: // value for 'internalCode'
 *   },
 * });
 */
export function useAddSupplierPrioritizedProductMutation(baseOptions?: Apollo.MutationHookOptions<AddSupplierPrioritizedProductMutation, AddSupplierPrioritizedProductMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddSupplierPrioritizedProductMutation, AddSupplierPrioritizedProductMutationVariables>(AddSupplierPrioritizedProductDocument, options);
      }
export type AddSupplierPrioritizedProductMutationHookResult = ReturnType<typeof useAddSupplierPrioritizedProductMutation>;
export type AddSupplierPrioritizedProductMutationResult = Apollo.MutationResult<AddSupplierPrioritizedProductMutation>;
export type AddSupplierPrioritizedProductMutationOptions = Apollo.BaseMutationOptions<AddSupplierPrioritizedProductMutation, AddSupplierPrioritizedProductMutationVariables>;
export const AddSupplierPrioritizedBrandDocument = gql`
    mutation AddSupplierPrioritizedBrand($internalCode: String!) {
  addSupplierPrioritizedBrand(internalCode: $internalCode)
}
    `;
export type AddSupplierPrioritizedBrandMutationFn = Apollo.MutationFunction<AddSupplierPrioritizedBrandMutation, AddSupplierPrioritizedBrandMutationVariables>;

/**
 * __useAddSupplierPrioritizedBrandMutation__
 *
 * To run a mutation, you first call `useAddSupplierPrioritizedBrandMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddSupplierPrioritizedBrandMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addSupplierPrioritizedBrandMutation, { data, loading, error }] = useAddSupplierPrioritizedBrandMutation({
 *   variables: {
 *      internalCode: // value for 'internalCode'
 *   },
 * });
 */
export function useAddSupplierPrioritizedBrandMutation(baseOptions?: Apollo.MutationHookOptions<AddSupplierPrioritizedBrandMutation, AddSupplierPrioritizedBrandMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddSupplierPrioritizedBrandMutation, AddSupplierPrioritizedBrandMutationVariables>(AddSupplierPrioritizedBrandDocument, options);
      }
export type AddSupplierPrioritizedBrandMutationHookResult = ReturnType<typeof useAddSupplierPrioritizedBrandMutation>;
export type AddSupplierPrioritizedBrandMutationResult = Apollo.MutationResult<AddSupplierPrioritizedBrandMutation>;
export type AddSupplierPrioritizedBrandMutationOptions = Apollo.BaseMutationOptions<AddSupplierPrioritizedBrandMutation, AddSupplierPrioritizedBrandMutationVariables>;
export const AddSupplierPrioritizedCategoryDocument = gql`
    mutation AddSupplierPrioritizedCategory($internalCode: String!) {
  addSupplierPrioritizedCategory(internalCode: $internalCode)
}
    `;
export type AddSupplierPrioritizedCategoryMutationFn = Apollo.MutationFunction<AddSupplierPrioritizedCategoryMutation, AddSupplierPrioritizedCategoryMutationVariables>;

/**
 * __useAddSupplierPrioritizedCategoryMutation__
 *
 * To run a mutation, you first call `useAddSupplierPrioritizedCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddSupplierPrioritizedCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addSupplierPrioritizedCategoryMutation, { data, loading, error }] = useAddSupplierPrioritizedCategoryMutation({
 *   variables: {
 *      internalCode: // value for 'internalCode'
 *   },
 * });
 */
export function useAddSupplierPrioritizedCategoryMutation(baseOptions?: Apollo.MutationHookOptions<AddSupplierPrioritizedCategoryMutation, AddSupplierPrioritizedCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddSupplierPrioritizedCategoryMutation, AddSupplierPrioritizedCategoryMutationVariables>(AddSupplierPrioritizedCategoryDocument, options);
      }
export type AddSupplierPrioritizedCategoryMutationHookResult = ReturnType<typeof useAddSupplierPrioritizedCategoryMutation>;
export type AddSupplierPrioritizedCategoryMutationResult = Apollo.MutationResult<AddSupplierPrioritizedCategoryMutation>;
export type AddSupplierPrioritizedCategoryMutationOptions = Apollo.BaseMutationOptions<AddSupplierPrioritizedCategoryMutation, AddSupplierPrioritizedCategoryMutationVariables>;
export const RemoveSupplierPrioritizedProductDocument = gql`
    mutation RemoveSupplierPrioritizedProduct($internalCode: String!) {
  removeSupplierPrioritizedProduct(internalCode: $internalCode)
}
    `;
export type RemoveSupplierPrioritizedProductMutationFn = Apollo.MutationFunction<RemoveSupplierPrioritizedProductMutation, RemoveSupplierPrioritizedProductMutationVariables>;

/**
 * __useRemoveSupplierPrioritizedProductMutation__
 *
 * To run a mutation, you first call `useRemoveSupplierPrioritizedProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveSupplierPrioritizedProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeSupplierPrioritizedProductMutation, { data, loading, error }] = useRemoveSupplierPrioritizedProductMutation({
 *   variables: {
 *      internalCode: // value for 'internalCode'
 *   },
 * });
 */
export function useRemoveSupplierPrioritizedProductMutation(baseOptions?: Apollo.MutationHookOptions<RemoveSupplierPrioritizedProductMutation, RemoveSupplierPrioritizedProductMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveSupplierPrioritizedProductMutation, RemoveSupplierPrioritizedProductMutationVariables>(RemoveSupplierPrioritizedProductDocument, options);
      }
export type RemoveSupplierPrioritizedProductMutationHookResult = ReturnType<typeof useRemoveSupplierPrioritizedProductMutation>;
export type RemoveSupplierPrioritizedProductMutationResult = Apollo.MutationResult<RemoveSupplierPrioritizedProductMutation>;
export type RemoveSupplierPrioritizedProductMutationOptions = Apollo.BaseMutationOptions<RemoveSupplierPrioritizedProductMutation, RemoveSupplierPrioritizedProductMutationVariables>;
export const RemoveSupplierPrioritizedBrandDocument = gql`
    mutation RemoveSupplierPrioritizedBrand($internalCode: String!) {
  removeSupplierPrioritizedBrand(internalCode: $internalCode)
}
    `;
export type RemoveSupplierPrioritizedBrandMutationFn = Apollo.MutationFunction<RemoveSupplierPrioritizedBrandMutation, RemoveSupplierPrioritizedBrandMutationVariables>;

/**
 * __useRemoveSupplierPrioritizedBrandMutation__
 *
 * To run a mutation, you first call `useRemoveSupplierPrioritizedBrandMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveSupplierPrioritizedBrandMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeSupplierPrioritizedBrandMutation, { data, loading, error }] = useRemoveSupplierPrioritizedBrandMutation({
 *   variables: {
 *      internalCode: // value for 'internalCode'
 *   },
 * });
 */
export function useRemoveSupplierPrioritizedBrandMutation(baseOptions?: Apollo.MutationHookOptions<RemoveSupplierPrioritizedBrandMutation, RemoveSupplierPrioritizedBrandMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveSupplierPrioritizedBrandMutation, RemoveSupplierPrioritizedBrandMutationVariables>(RemoveSupplierPrioritizedBrandDocument, options);
      }
export type RemoveSupplierPrioritizedBrandMutationHookResult = ReturnType<typeof useRemoveSupplierPrioritizedBrandMutation>;
export type RemoveSupplierPrioritizedBrandMutationResult = Apollo.MutationResult<RemoveSupplierPrioritizedBrandMutation>;
export type RemoveSupplierPrioritizedBrandMutationOptions = Apollo.BaseMutationOptions<RemoveSupplierPrioritizedBrandMutation, RemoveSupplierPrioritizedBrandMutationVariables>;
export const RemoveSupplierPrioritizedCategoryDocument = gql`
    mutation RemoveSupplierPrioritizedCategory($internalCode: String!) {
  removeSupplierPrioritizedCategory(internalCode: $internalCode)
}
    `;
export type RemoveSupplierPrioritizedCategoryMutationFn = Apollo.MutationFunction<RemoveSupplierPrioritizedCategoryMutation, RemoveSupplierPrioritizedCategoryMutationVariables>;

/**
 * __useRemoveSupplierPrioritizedCategoryMutation__
 *
 * To run a mutation, you first call `useRemoveSupplierPrioritizedCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveSupplierPrioritizedCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeSupplierPrioritizedCategoryMutation, { data, loading, error }] = useRemoveSupplierPrioritizedCategoryMutation({
 *   variables: {
 *      internalCode: // value for 'internalCode'
 *   },
 * });
 */
export function useRemoveSupplierPrioritizedCategoryMutation(baseOptions?: Apollo.MutationHookOptions<RemoveSupplierPrioritizedCategoryMutation, RemoveSupplierPrioritizedCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveSupplierPrioritizedCategoryMutation, RemoveSupplierPrioritizedCategoryMutationVariables>(RemoveSupplierPrioritizedCategoryDocument, options);
      }
export type RemoveSupplierPrioritizedCategoryMutationHookResult = ReturnType<typeof useRemoveSupplierPrioritizedCategoryMutation>;
export type RemoveSupplierPrioritizedCategoryMutationResult = Apollo.MutationResult<RemoveSupplierPrioritizedCategoryMutation>;
export type RemoveSupplierPrioritizedCategoryMutationOptions = Apollo.BaseMutationOptions<RemoveSupplierPrioritizedCategoryMutation, RemoveSupplierPrioritizedCategoryMutationVariables>;