import type * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SuggestionsQueryVariables = Types.Exact<{
  input?: Types.InputMaybe<Types.RecommendationsInput>;
}>;


export type SuggestionsQuery = { __typename?: 'Query', recommendations: Array<{ __typename?: 'Recommendation', title: string, description?: string | null, products: Array<{ __typename?: 'ListingProductV2', id: number, name: string, image: string, brand: string, notification: boolean, description?: string | null, category: { __typename?: 'ListingCategory', id: number, name: string, image?: string | null }, displays: Array<{ __typename?: 'ListingDisplayV2', id: number, default: boolean, units: number, storeUnavailabiliy: boolean, price: { __typename?: 'Money', amount: number, currency: string, scale: number }, unitPrice: { __typename?: 'Money', amount: number, currency: string, scale: number } }>, pillInfo?: { __typename?: 'PillPromotionInfo', pillType: Types.PillType, text: string, promotionId?: number | null } | null }> }> };


export const SuggestionsDocument = gql`
    query Suggestions($input: RecommendationsInput) {
  recommendations(input: $input) {
    products {
      id
      name
      image
      brand
      notification
      category {
        id
        name
        image
      }
      displays {
        id
        default
        units
        price {
          amount
          currency
          scale
        }
        unitPrice {
          amount
          currency
          scale
        }
        storeUnavailabiliy
      }
      description
      pillInfo {
        pillType
        text
        promotionId
      }
    }
    title
    description
  }
}
    `;

/**
 * __useSuggestionsQuery__
 *
 * To run a query within a React component, call `useSuggestionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSuggestionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSuggestionsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSuggestionsQuery(baseOptions?: Apollo.QueryHookOptions<SuggestionsQuery, SuggestionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SuggestionsQuery, SuggestionsQueryVariables>(SuggestionsDocument, options);
      }
export function useSuggestionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SuggestionsQuery, SuggestionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SuggestionsQuery, SuggestionsQueryVariables>(SuggestionsDocument, options);
        }
export type SuggestionsQueryHookResult = ReturnType<typeof useSuggestionsQuery>;
export type SuggestionsLazyQueryHookResult = ReturnType<typeof useSuggestionsLazyQuery>;
export type SuggestionsQueryResult = Apollo.QueryResult<SuggestionsQuery, SuggestionsQueryVariables>;