import type * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FindPaymentMethodsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type FindPaymentMethodsQuery = { __typename?: 'Query', findStoreProfile: { __typename?: 'FindStoreProfileResponse', profiles: Array<{ __typename?: 'Profile', setting: { __typename?: 'Setting', paymentMethods?: Array<{ __typename?: 'PaymentMethod', id: number, code: string, name: string }> | null, credit?: { __typename?: 'Credit', id?: number | null, defaulter?: boolean | null, debtor?: boolean | null, money?: { __typename?: 'Money', amount: number, currency: string, scale: number } | null } | null, preference?: { __typename?: 'Preference', paymentMethod?: { __typename?: 'PaymentMethod', id: number, code: string, name: string } | null } | null } } | null> } };


export const FindPaymentMethodsDocument = gql`
    query FindPaymentMethods {
  findStoreProfile {
    profiles {
      setting {
        paymentMethods {
          id
          code
          name
        }
        credit {
          id
          money {
            amount
            currency
            scale
          }
          defaulter
          debtor
        }
        preference {
          paymentMethod {
            id
            code
            name
          }
        }
      }
    }
  }
}
    `;

/**
 * __useFindPaymentMethodsQuery__
 *
 * To run a query within a React component, call `useFindPaymentMethodsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindPaymentMethodsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindPaymentMethodsQuery({
 *   variables: {
 *   },
 * });
 */
export function useFindPaymentMethodsQuery(baseOptions?: Apollo.QueryHookOptions<FindPaymentMethodsQuery, FindPaymentMethodsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindPaymentMethodsQuery, FindPaymentMethodsQueryVariables>(FindPaymentMethodsDocument, options);
      }
export function useFindPaymentMethodsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindPaymentMethodsQuery, FindPaymentMethodsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindPaymentMethodsQuery, FindPaymentMethodsQueryVariables>(FindPaymentMethodsDocument, options);
        }
export type FindPaymentMethodsQueryHookResult = ReturnType<typeof useFindPaymentMethodsQuery>;
export type FindPaymentMethodsLazyQueryHookResult = ReturnType<typeof useFindPaymentMethodsLazyQuery>;
export type FindPaymentMethodsQueryResult = Apollo.QueryResult<FindPaymentMethodsQuery, FindPaymentMethodsQueryVariables>;