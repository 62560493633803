export enum lang {
  es = "es",
  en = "en",
  esHN = "es-HN",
  esAR = "es-AR",
  esMX = "es-MX"
}

export const locales = ["es", "en", "es-HN", "es-AR", "es-MX"];

export const localesRegex = locales.join("|");

export type localesType = lang.es | lang.en | lang.esHN | lang.esAR | lang.esMX;

export const defaultLocale = lang.es;

export const timeZone = "Central Standard Time";
